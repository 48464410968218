import React, { useState } from "react";
import { createDataTable } from "../components/reusableComponents/DataTable";
import { formatCurrency, getFormatedDateTime } from "../utils/formatter";
import { Box, IconButton, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
import { InfoText } from "./TripList";
// Trid id, location(optional), date/time, toll cost

const TripSummary = ({ tolls: rows }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const formattedRow = rows.map((e) => {
  let row = {
    id: e?.start?.id ?? e?.id,
    tagPriCost: e?.tagPriCost,
    name: e?.start?.name ?? e?.name,
    arrivalTime: e?.start?.arrival?.time ? getFormatedDateTime(e.start.arrival.time) : getFormatedDateTime(e?.arrival?.time),
    exitName: e?.end?.name,
    exitTime: getFormatedDateTime(e?.end?.arrival?.time),
    type: e?.type,
  };

  if (e.isExpressLane) {
    row.expressLaneToll = e.tagPriCost ?? 0;
  } else {
    row.gpsToll = e.tagPriCost ?? 0;
  }

  return row;
});


  console.log(formattedRow);
  // Realistic toll road types
  const roadNames = [
    "Interstate 5",
    "Interstate 10",
    "Interstate 95",
    "Interstate 80",
    "Interstate 40",
    "US Route 101",
    "US Route 66",
    "US Route 20",
    "US Route 1",
    "US Route 30",
    "California State Route 1 (Pacific Coast Highway)",
    "California State Route 99",
    "New Jersey Turnpike",
    "Florida's Turnpike",
    "New York State Thruway",
    "Massachusetts Turnpike",
    "Pennsylvania Turnpike",
    "Ohio Turnpike",
    "Illinois Tollway",
    "New York State Route 9A",
  ];

  // Realistic tag types
  const tagTypes = ["EZ Pass", "FasTrak", "Peach Pass", "SunPass", "TollTag", "E-PASS", "I-PASS"];

  const DataTable = createDataTable({
    rows:formattedRow,
    columns: ({ column }) => [
      column("id", { header: "Toll ID" }),
      column("gpsToll", {
        header: "GPS Toll",
        cell: (item) => {
          return (
            <Box
              sx={{
                fontSize: "14px !important",
                // paddingLeft: "5px !important",
              }}
            >
              {formatCurrency(item.row.original.gpsToll ?? 0)}
            </Box>
          );
        },
        type: "currency",
        meta: { align: "right", export: true },
      }),
      column('expressLaneToll', {
        header: 'Express Toll Cost',
        headerRender: (val) => (
          <InfoText
            tooltip="This is an average estimated toll cost if you travel on express lanes."
            className="MuiDataGrid-columnHeaderTitle"
          >
            {val}
          </InfoText>
        ),
        cell: (item) => {
          return (
            <Box
              sx={{
                fontSize: "14px !important",
                // paddingLeft: "5px !important",
              }}
            >
              {formatCurrency(item.row.original.expressLaneToll ?? 0)}
            </Box>
          );
        },
        type: "currency",
        meta: { align: "right", export: true },
      }),
      column("name", { header: "Entry Plaza" }),
      column("exitName", { header: "Exit Plaza" }),
      column("arrivalTime", { header: "Entry Time" }), // Assuming you have a formatDateTime function
      column("exitTime", { header: "Exit Time" }), // Assuming you have a formatDateTime function
    ],
  });

  // isExpressLane
  return (
    <>
      <h1 className="text-2xl px-4 my-4">Trip Summary</h1>
      <DataTable infinitePagination 
      initialState={{
        pagination: {
          pageIndex: currentPage - 1,
          pageSize: rowsPerPage, // Set the initial page number to 2
        },
      }}
      setRowsPerPage={setRowsPerPage}
       onPageChange={async (isLastPage, requestingNextPage, setPageIndex, page) => {
        if (requestingNextPage && isLastPage) {
          setPageIndex(page);
          // setCurrentPage(currentPage+1)
        } else {
          setPageIndex(page);
        }
        setCurrentPage(page + 1);
      }}
      >
        <DataTable.Search />
        <DataTable.Export filename={'trip-summary.csv'} />
      </DataTable>
    </>
  );
};

export default TripSummary;
