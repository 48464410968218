import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckCircleOutline, ChevronLeftOutlined } from "@mui/icons-material";

import SearchIcon from "@mui/icons-material/Search";

export default function VehicleDropDown({ allVehicleName, selected, setSelected, setCurrentPage }) {
  const [query, setQuery] = useState("");

  const filteredallVehicleName =
    query === ""
      ? allVehicleName
      : allVehicleName.filter((vehicle) =>
          vehicle.vehicle_name
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query?.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="z-[1000] overflow-y-visible flex items-center justify-center">
    <Combobox value={selected} className="z-50 border-none focus:ring-0 w-60">
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-0"
            displayValue={(vehicle) => vehicle.vehicle_name}
            placeholder="Search Vehicle"
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-0 focus:outline-none sm:text-sm">
            {filteredallVehicleName.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredallVehicleName.map((vehicle) => (
                <Combobox.Option
                  key={vehicle.vehicle_id}
                  onClick={() => {setSelected(vehicle); setCurrentPage(1)}}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-tollguru-primary text-white" : "text-gray-900"
                    }`
                  }
                  value={vehicle}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"}`}
                      >
                        {vehicle.vehicle_name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-tollguru-primary"
                          }`}
                        >
                          <CheckCircleOutline className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
    </div>
  );
}
