import { Stack } from "@mui/system";

import DailyTollCost from '../components/DailyTollCost';
import DailyTollFrequency from "../components/DailyTollFrequency";
import DashboardOverview from '../components/DashboardOverview';

const Dashboard = ({ navigate }) => {

  return (
    <>
      <div className="relative rounded-lg py-6 flex flex-col gap-2  ">
    <h1 className="text-2xl px-4 font-medium">Tolls Overview</h1>
        <DashboardOverview />

        {/* <Stack direction="row" className="gap-[inherit] w-full">
          <DailyTollCost />
          <DailyTollFrequency />
        </Stack> */}
      </div>
    </>
  );
};

export default Dashboard;
