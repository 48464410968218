import { useMap } from "react-leaflet";
import LeafletMap from "../leafletMap/LeafletMap";
import { useEffect, useRef } from "react";
import { getFormatedDateTime } from "../../utils/formatter";
import { currencySymbols } from "../../data/data";
import "leaflet-markers-canvas";
import L from "leaflet";
// import { currencySymbols } from "@/data/data";
// import { getFormatedDateTime } from "@/utils/formatter";
const FitBounds = ({ bounds }) => {
  const map = useMap();
  const prevBounds = useRef(null);
  useEffect(() => {
    if (bounds && bounds !== prevBounds.current) {
      map.fitBounds(bounds);
      prevBounds.current = bounds;
    }
  }, [map, bounds]);

  return null;
};

const fillBlueOptions = { fillColor: "blue" };
const blackOptions = { color: "black" };
const limeOptions = { color: "lime" };
const purpleOptions = { color: "purple" };
const redOptions = { color: "red" };
const greenOptions = { color: "green" };
const blueOptions = { color: "#1AA1F0", weight: 5 };

function decodePolyline(polyline) {
  var index = 0;
  var lat = 0;
  var lng = 0;
  var coordinates = [];

  while (index < polyline.length) {
    var shift = 0;
    var result = 0;

    do {
      var byte = polyline.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    var dlat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;

    do {
      var byte = polyline.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    var dlng = result & 1 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    coordinates.push([lat / 1e5, lng / 1e5]);
  }

  return coordinates;
}

const ReactMap = ({ coordinates, tolls, polyline }) => {
  const coords = decodePolyline(polyline);

  const midPoint = Math.trunc(coords.length / 2);
  // console.log(coords[midPoint][0])
  const center = [coords[midPoint][0], coords[midPoint][1]];
  const poly = coords.map((point) => [point[0], point[1]]);

  // Calculate bounds for all coordinates
  const bounds = coords;

  const tollsCoordinates = tolls.flatMap((toll) => {
    if (toll.lat && toll.lng) {
      return {
        arrivalTime: toll.timestamp_localized,
        latitude: toll.lat,
        longitude: toll.lng,
        name: toll.name,
        tagCost: toll.tagCost,
        cashCost: toll.cashCost,
        licensePlateCost: toll.licensePlateCost,
        currency: toll.currency,
        tagPriCost: toll.tagPriCost,
        prepaidCardCost: toll.prepaidCardCost,
      };
    } else if (toll.start && toll.end) {
      return [
        {
          arrivalTime: toll.start.arrival.time,
          latitude: toll.start.lat,
          longitude: toll.start.lng,
          name: toll.start.name,
          tagCost: toll.tagCost,
          cashCost: toll.cashCost,
          licensePlateCost: toll.licensePlateCost,
          currency: toll.currency,
          tagPriCost: toll.tagPriCost,
          prepaidCardCost: toll.prepaidCardCost,
        },
        {
          exitTime: toll.end.arrival.time,
          latitude: toll.end.lat,
          longitude: toll.end.lng,
          name: toll.end.name,
          tagCost: toll.tagCost,
          cashCost: toll.cashCost,
          licensePlateCost: toll.licensePlateCost,
          currency: toll.currency,
          tagPriCost: toll.tagPriCost,
          prepaidCardCost: toll.prepaidCardCost,
        },
      ];
    }
    return null;
  });

  const test = [];
  function getTollIcon(toll){
    const iconToll = "https://cdn.tollguru.com/github/Wiki/calc/toll.png";
    const iconTag = "https://cdn.tollguru.com/github/Wiki/calc/toll-tag.png";
    const iconCash = "https://cdn.tollguru.com/github/Wiki/calc/toll-cash.png";

      return ((toll.tagCost !== undefined &&
      toll.tagCost !== null &&
      toll.tagCost !== -1 &&
      toll.tagCost !== false &&
      Number(toll.tagCost) >= 0)
        ? iconTag
        : toll.cashCost && toll.cashCost !== null
        ? iconCash
        : iconToll
      )
  }
  return (
    <div className="tollmap w-full md:col-span-2 relative lg:h-[60vh] h-[50vh] m-auto overflow-hidden border  bg-white z-10 ">
      {center.length !== 0 && (
        <LeafletMap
          className="w-full h-full absolute top-0 left-0"
          // center={center}
          zoom={11}
        >
          {({ TileLayer, CircleMarker, Marker, Popup, Polyline, Tooltip }) => (
            <>
              {/* FitBounds component to automatically adjust the map */}
              {bounds.length !== 0 && <FitBounds bounds={bounds} />}

              <TileLayer // attribution="&amp;copy <a target='_blank' href='https://osm.org/copyright'>OpenStreetMap</a> contributors"
                url="http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en"
              />

              {
                <Marker
                position={coords[0]}
                icon={L.divIcon({
                  html: `<div class="my-icon"><img src=${'https://cdn.tollguru.com/github/Wiki/calc/from.png'} alt="My icon"></div>`,
                  className: "my-icon",
                  iconSize: [25, 25],
                  iconAnchor: [16, 16],
                })}
              />}
              {
                <Marker
                position={coords[coords.length-1]}
                icon={L.divIcon({
                  html: `<div class="my-icon"><img src=${'https://cdn.tollguru.com/github/Wiki/calc/to.png'} alt="My icon"></div>`,
                  className: "my-icon",
                  iconSize: [25, 25],
                  iconAnchor: [16, 16],
                })}
              />
              }
              {tollsCoordinates.length !== 0 &&
                tollsCoordinates?.map((toll, index) => (
                  <>
                    <Marker
                      key={index}
                      position={[toll.latitude, toll.longitude]}
                      icon={L.divIcon({
                        html: `<div class="my-icon"><img src=${getTollIcon(toll)} alt="My icon"></div>`,
                        className: "my-icon",
                        iconSize: [25, 25],
                        iconAnchor: [16, 16],
                      })}
                    >
                      <Tooltip>
                        <div className="flex flex-col text-left">
                          <span className="font-bold"> {toll.name} </span>
                          {toll.arrivalTime && (
                            <span>Start Time: {getFormatedDateTime(toll.arrivalTime)}</span>
                          )}
                          {toll.exitTime && (
                            <span>Exit Time: {getFormatedDateTime(toll.exitTime)}</span>
                          )}
                          <span>
                            License Plate Cost:{" "}
                            {currencySymbols[toll.currency] +
                              " " +
                              (toll.licensePlateCost ? toll.licensePlateCost : 0)}
                          </span>
                          <span>
                            Cash Cost:{" "}
                            {currencySymbols[toll.currency] +
                              " " +
                              (toll.cashCost ? toll.cashCost : 0)}
                          </span>
                          <span>
                            Tag Cost:{" "}
                            {currencySymbols[toll.currency] +
                              " " +
                              (toll.tagCost ? toll.tagCost : 0)}
                          </span>
                          <span>
                            Tag Pri Cost:{" "}
                            {currencySymbols[toll.currency] +
                              " " +
                              (toll.tagPriCost ? toll.tagPriCost : 0)}
                          </span>
                          <span>
                            Pre-paid Cost:{" "}
                            {currencySymbols[toll.currency] +
                              " " +
                              (toll.prepaidCardCost ? toll.prepaidCardCost : 0)}
                          </span>
                        </div>
                      </Tooltip>
                    </Marker>
                  </>
                ))}

              {poly.length !== 0 && <Polyline pathOptions={blueOptions} positions={poly} />}
            </>
          )}
        </LeafletMap>
      )}
    </div>
  );
};

export default ReactMap;
