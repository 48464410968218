import { atom } from 'jotai';

function atomWithLocalStorage(key, initialValue) {
  const isBrowser = typeof window !== 'undefined';
  const baseAtom = atom(isBrowser && (localStorage.getItem(key) ?? JSON.stringify(initialValue)));

  return atom(
    (get) => JSON.parse(get(baseAtom) || '{}'),
    (_, set, val) => {
      const newVal = JSON.stringify(val);
      set(baseAtom, newVal);
      if (isBrowser) localStorage.setItem(key, newVal);
    },
  );
}

export const defaultDateRange = {
  date: {
    start: new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .slice(0, 10),
    end: new Date().toISOString().slice(0, 10),
  },
  value: 'DEFAULT',
};

export const globalDateAtom = atomWithLocalStorage('global-date', defaultDateRange);
