import { useState, createContext, useContext, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Stack,
  Skeleton,
  Button,
} from '@mui/material';
import Papa from 'papaparse';

import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { formatCurrency, formatDateTime } from '../../utils/formatter';
import { SearchFilter, filterButtonStyles } from '../utils';
import { DownloadTwoTone } from '@mui/icons-material';

function SkeletonRows({ n, columns }) {
  const SkeletonRow = () => (
    <TableRow>
      {columns.map((_, i) => (
        <TableCell key={i} sx={{ minWidth: 0, width: '30%' }}>
          <Skeleton variant="text" animation="pulse" />
        </TableCell>
      ))}
    </TableRow>
  );

  const skeletonRows = Array.from({ length: n }, (_, i) => (
    <SkeletonRow key={i} />
  ));

  return <>{skeletonRows}</>;
}


const TableContext = createContext(null);

function DataTable({
  loading = false,
  noWrapper = false,
  children,
  onPageChange,
  infinitePagination,
  data,
  columns,
  initialState,
  state,
  sx,
  onRowClick,
  id_column_name,
  setRowsPerPage,
  cursor_pointer=false,
  isVIN=false
}
) {
  const [sortFilters, setSortFilters] = useState(initialState?.sorting ?? []);
  const baseState = { pagination: { pageSize: 50 }, ...initialState };


  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),

    initialState: baseState,
    state: { sorting: sortFilters, ...state },

    // sorting
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: true,
    onSortingChange(updaterOrValue) {
      if (typeof updaterOrValue !== 'function' || !sortFilters) return;
      const d = updaterOrValue(sortFilters);
      const id = d?.[0]?.id;
      columns.find((c) => c.id === id)?.meta?.onSort?.();
      setSortFilters(updaterOrValue);
    },

    // filtering
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      weakStartsWith: (row, columnId, value) => {
        const data = row.getValue(columnId);
        if (data) return `${data}`.toLowerCase().startsWith(value.toLowerCase());
        return false;
      }
    },

    // pagination
    getPaginationRowModel: !baseState.pagination || (baseState.pagination.pageSize ?? 0) > 0 ? getPaginationRowModel() : undefined,
    autoResetPageIndex: false,
  });


  const { pageSize, pageIndex } = table.getState().pagination;

  const tableContent = (
    <Stack sx={{ height: '100%', maxHeight: '55vh', ...(noWrapper && sx) }}>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead sx={{
            '& > tr > th': {
              fontWeight: 600,
              fontSize: 14,
              textTransform: 'none',
            }
          }}>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} align={header.column?.columnDef?.meta?.align} sx={{ whiteSpace: 'nowrap', paddingLeft: header.column?.columnDef?.meta?.isVIN ? '40px' : '16px' }}>
                    <TableSortLabel
                      sx={{
                        flexDirection: 'row',
                        cursor: header.column.getCanSort() ? 'pointer' : 'initial',
                        color: '#1da1f2 !important',
                        ':hover > .MuiTableSortLabel-icon': { opacity: 1 },

                        '.MuiTableSortLabel-icon': {
                          color: header.column.getIsSorted() ? 'inherit !important' : 'grey',
                          opacity: 0.5,
                          marginRight:'-10px',
                          paddingRight:'0px !important'
                        },

                      }}
                      hideSortIcon={!header.column.getCanSort()}
                      active={!!header.column.getIsSorted()}
                      direction={header.column.getIsSorted() || 'desc'}
                      IconComponent={ArrowDropDownTwoToneIcon}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody sx={{
            // height: '100%',
            '& > .MuiTableRow-root > .MuiTableCell-root': {
              paddingBlock: 0,
              fontSize: '14px !important',
              height: '55px',
            },
          }}>
            {loading ? <SkeletonRows columns={columns} n={7} />
              : data?.length > 0 && table?.getRowModel()?.rows.length > 0
                ? table.getRowModel().rows.map(row => (
                  
                  <TableRow
                    hover
                    onClick={() => id_column_name && onRowClick(row.original[id_column_name])}
                    key={row.id}
                    sx={{
                      '&:hover': { backgroundColor: 'rgba(34, 51, 84, 0.1) !important' },
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: cursor_pointer ? 'pointer' : '',  

                    }}
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell key={cell.id} align={cell.column.columnDef?.meta?.align} sx={{ whiteSpace: 'nowrap', paddingLeft: cell.column?.columnDef?.meta?.isVIN ? '40px' : '16px'  }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
                : (
                  <TableRow>
                    <TableCell colSpan={columns?.length} sx={{ opacity: 0.5 }}>
                      <span style={{ padding: '2rem', display: 'grid', placeItems: 'center' }}>
                        <InboxTwoToneIcon sx={{ fontSize: '5rem' }} color="disabled" />
                        <Typography fontSize="1.2rem" fontWeight="bold" color="gray">No data to display</Typography>
                      </span>
                    </TableCell>
                  </TableRow>
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {(!loading && (baseState.pagination?.pageSize ?? 0) > 0 && data?.length > 0) && (
        <Box paddingInline="0.5rem">
          <TablePagination
            backIconButtonProps={{ disabled: loading || pageIndex <= 0 }}
            nextIconButtonProps={{ disabled: loading || pageIndex + (infinitePagination ? 0 : 1) >= table.getPageCount() }}
            count={infinitePagination ? -1 : table.getFilteredRowModel()?.rows?.length}
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={async (_, page) => {
              onPageChange(
                !table.getCanNextPage(),
                page > pageIndex,
                table.setPageIndex,
                page
              );
              // .then(()=>table.setPageIndex(page))
            }}
            onRowsPerPageChange={(e) => {
              const size = e.target.value ? Number(e.target.value) : 10;
              table.setPageSize(size);
              setRowsPerPage(size);
              onPageChange(false, false, table.setPageIndex, 0);
            }}
            labelDisplayedRows={(info) => {
              return (
                <span style={{ display: 'flex', gap: '0.5rem', justifyContent: 'space-between' }}>
                  <span>Page {info.page + 1}</span>
                  <span style={{ width: '1px', backgroundColor: 'lightgray' }} />
                  <span>Rows {info.from} - {info.to}</span>
                </span>
              );
            }}
          />
        </Box>
      )}
    </Stack>
  );

  const tableInner = <>
    {
      children &&
      <CardHeader
        sx={{
          '& > .MuiCardHeader-content': { flex: 0 },
          '& > .MuiCardHeader-action': { flex: 1 }
        }}
        action={
          <Stack
            direction="row"
            gap="1rem"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="center"
            position="relative"
          >
            {loading ? <span /> : children}
          </Stack>
        }
      />
    }
    <CardContent sx={{ padding: '0 !important', flexGrow: 1 }}>
      {tableContent}
    </CardContent>
  </>;

  if (noWrapper) return (
    <TableContext.Provider value={{ table, data, columns }}>
      {tableInner}
    </TableContext.Provider>
  );

  return (
    <TableContext.Provider value={{ table, data, columns }}>
      <Card sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
        {tableInner}
      </Card>
    </TableContext.Provider>
  );
}


function convertToCSV(data) {
  const csv = Papa.unparse(data, {
    header: true,
    delimiter: ',',
  });
  return csv;
};

function Export({ filename = 'table-data.csv', data, columns }) {
  function exportCSV() {
    if (!data || data.length === 0) return;
    const json = data.map((row) =>
      columns.reduce((prev, { meta, id, header, accessorFn }) => {
        const h = typeof header === 'string' ? header : meta?.headerText;
        return meta?.export ? ({ ...prev, [h]: accessorFn ? accessorFn(row) : row[id] }) : prev;
      }, {})
    );

    const blob = new Blob([convertToCSV(json)], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', filename);

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <Button startIcon={<DownloadTwoTone />} sx={filterButtonStyles} onClick={exportCSV}>Export</Button>
  );
}


function TableHelpers() {
  const columnHelper = createColumnHelper();
 

  return {
    group: columnHelper.group,
    column:(...[
      name,
      { header, headerRender, type, ...opts },
      accessor = name,
    ]
    ) => {
      const newHeader = headerRender && typeof header === 'string' ? headerRender(header) : header;

      if (type === 'action') return columnHelper.display({
        id: name,
        header: newHeader,
        meta: { align: 'center' },
        ...opts
      });

      return columnHelper.accessor(accessor, {
        header: newHeader,
        id: name,
        cell: (item) => {
          const val = item.getValue();
          if (!val) return '-';
          if (type === 'currency'){ return formatCurrency(val);}
          if (type === 'date') return formatDateTime(val);
          return val;
        },
        meta: { export: true, ...(opts?.meta && opts.meta), headerText: header },
        ...opts
      });
    },
  };
}
function Search({ onEnter, column, noFilter = false }) {
  const { table } = useContext(TableContext);

  return (
      <SearchFilter
          onEnter={onEnter}
          onChange={(e) => {
              if (noFilter) return;

              const val = String(e.target.value);
              if (column) {
                  table.setColumnFilters([{ id: String(column), value: val }]);
                  return;
              }

              table.setGlobalFilter(val);
          }}
      />
  );
}
export function createDataTable({ rows, columns, id_column_name }) {
  const helpers = TableHelpers();
  const cols = columns(helpers);

  const api = { rows, cols };
  const subComponents = {
    Search: Search,
    Export: (props) => <Export {...props} data={rows} columns={cols} />,
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const D = useRef((props) => {
    const { rows, cols } = D.current.api;
    return <DataTable {...props} data={rows} columns={cols} id_column_name={id_column_name} />;
  });

  Object.assign(D.current, { api });
  Object.assign(D.current, subComponents);

  return D.current;
}
