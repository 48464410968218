export default function Navbar({ navigate, currentView, loading }) {
  const cdnUrl = "https://cdn.tollguru.com";

  return (
    <div className="flex items-center justify-between px-5 py-3 bg-white shadow-md text-[14px]">
      <div onClick={() => navigate("dashboard")} className="inline-block">
        <img
          className="w-[100px] h-[50px] object-cover transform scale-150"
          src={cdnUrl + "/geotab/toll-tally-logo.png"}
          alt="tolltally-logo"
        />
      </div>
      <div className="flex">
        <div
          onClick={() => navigate("dashboard")}
          className={`block mr-4 cursor-pointer ${
            currentView === "dashboard" ? "bg-tollguru-primary text-white" : ""
          } px-5 py-1 rounded-2xl`}
        >
         Tolls Overview
        </div>
        <button
        disabled={loading?'true':''}
          onClick={() => navigate("trip-list", "all")}
          className={`block mr-4 cursor-pointer ${
            currentView === "trip-list" ? "bg-tollguru-primary text-white" : ""
          } px-5 py-1 rounded-2xl`}
        >
          GPS Tolls
        </button>{" "}
        <button
         disabled={loading?'true':''}
          onClick={() => navigate("vehicle-list")}
          className={`block mr-4 cursor-pointer ${
            currentView === "vehicle-list" ? "bg-tollguru-primary text-white" : ""
          } px-5 py-1 rounded-2xl`}
        >
          My Vehicles
        </button>
        {/* <div
          onClick={() => navigate("vehicle-settings")}
          className={`block mr-4 cursor-pointer ${
            currentView === "vehicle-settings" ? "bg-tollguru-primary text-white" : ""
          } px-5 py-1 rounded-2xl`}
        >
          My Vehicles
        </div> */}
      </div>
      <div></div>
    </div>
  );
}
