import axios from "axios";
import { useEffect, useState } from "react";
import VehicleInput from "./vehicleInput";
import { useQueryClient } from '@tanstack/react-query';

// Inside your component or hook
const VehicleSettingsForm = ({ closeModal, openModal, vehicleData, setVehicles,vehicles }) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    // unit: "101081",
    // vehicle_name: "VEH_1",
    // vehicle_make: "2000",
    // vehicle_model: "Ford F-150",
    // vin: "1G1YY36U075000000",
    // license_plate: "1ABC234",
    // license_plate_state: "Alabama",
    // vehicle_type: "2AxlesTruck",
    // vehicle_class: null,
    // axle_count: 2,
    // tire_count: 4,
    // vehicle_weight: 19500,
    // vehicle_height: "9.00",
    // tag_number: "",
    // tag_issuing_agency: "",
    // tag_type: "transponder",
  });
  const [vehicleType, setVehicleType] = useState('2AxlesAuto');
  const [loading, setLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  useEffect(()=>{
    if(vehicleData.vehicle_type) setVehicleType(vehicleData.vehicle_type);
  },[vehicleData])
  useEffect(() => {
    const isFormChanged = Object.keys(formData).some((key) => {
      return formData[key] !== ""; // Check if any field has a value
    });
    setFormChanged(isFormChanged);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const reqBody = { vehicle_type: vehicleType, vehicle_id:vehicleData.vehicle_id};
      if (formData.vehicle_height) reqBody["height"] = typeof formData.vehicle_height !='number'? Number(formData.vehicle_height):formData.vehicle_height;
      if (formData.vehicle_weight) reqBody["weight"] = typeof formData.vehicle_weight !='number'? Number(formData.vehicle_weight):formData.vehicle_weight;
      if (formData.vehicle_length) reqBody["length"] = typeof formData.vehicle_length !='number'? Number(formData.vehicle_length):formData.vehicle_length;
      if (!formChanged) {
        // If form hasn't changed, do not submit
        return;
      }
      setLoading(true);
      console.log(formData, vehicleType, vehicles);
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/vehicles/updateVehicle`, reqBody);
      const index = vehicles.findIndex(vehicle => vehicle.vehicle_id === vehicleData.vehicle_id);
      if(index !=-1){
        setVehicles([...vehicles.slice(0,index),{...vehicles[index], ...reqBody},...vehicles.slice(index+1)]);
      }
      console.log(res);
      const refetchVehicles = () => {
        queryClient.invalidateQueries(['vehicles', vehicleData.vehicle_id]);
      };
      refetchVehicles()
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };
  function checkValue(id, mx) {
    var input = document.getElementById(id);
    if (parseFloat(input.value) < 0) {
        input.value = 0;
    } else if (parseFloat(input.value) > mx) {
        input.value = mx;
    }
}

function isNumberKey(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    
    // Allow digits (0-9) and period (.) character
    if ((charCode !== 46 && charCode < 48) || charCode > 57 || event.target.value.indexOf('.') !== -1 && charCode === 46) {
        event.preventDefault();
        return false;
    }
    
    return true;
}


  return (
    <form
      onSubmit={handleSubmit}
      className=" bg-white rounded-lg flex flex-col justify-between "
    >
      <div className="h-auto overflow-auto pt-2 ">
        <h2 className="text-xl font-semibold mb-6">Update Vehicle Details - {vehicleData.geotab_vehicle_id}</h2>

        <div className="relative z-0 w-full mb-5 group">
          <VehicleInput vehicleType={vehicleType} setVehicleType={setVehicleType} setFormChanged={setFormChanged} />
        </div>{" "}

        <div className="relative z-0 w-full mb-5 group">
          <input
            type="number"
            name="vehicle_height"
            defaultValue={vehicleData?.height}
            id="vehicle_height"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
            min="0"
            max="25"
            step="0.01"
            onInput={(e)=>{checkValue("vehicle_height",25); handleChange(e)}}
            // onKeyDown={(event)=>{return isNumberKey(event)}}
          />
          <label
            htmlFor="vehicle_height"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Height (ft)
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onInput={(e)=>{checkValue("vehicle_length",25); handleChange(e)}}
            // onKeyDown={(event)=>{return isNumberKey(event)}}
            type="number"
            name="vehicle_length"
            min="0"
    max="25"
    step="0.01"
            defaultValue={vehicleData?.length}
            id="vehicle_length"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_length"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Length (ft)
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
             onInput={(e)=>{checkValue("vehicle_weight",100000); handleChange(e)}}
            //  onKeyDown={(event)=>{return isNumberKey(event)}}
             min="0"
             max="100000"
             step="0.01"
            type="number"
            name="vehicle_weight"
            defaultValue={vehicleData?.weight}
            id="vehicle_weight"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_weight"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Weight (lbs)
          </label>
        </div>
        <div className=" pt-3">
          <button
            type="submit"
            // disabled={loading}
            disabled={!formChanged || loading}
            className={`text-white  bg-tollguru-primary hover:bg-tollguru-primary focus:ring-4 focus:outline-none focus:ring-blue-300 flex justify-center gap-2 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center ${(!formChanged || loading) &&
              "cursor-not-allowed bg-tollguru-primary/60 hover:bg-tollguru-primary/60"
              }`}
          >
            {loading && (
              <div className=" flex items-center justify-center">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white border-t-white border-r-2 border-r-white border-b-2 border-b-white"></div>
              </div>
            )}
            {loading ? "Updating Vehicle..." : "Update Vehicle"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default VehicleSettingsForm;
