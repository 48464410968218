import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TextField, InputAdornment } from '@mui/material';


export function SearchFilter({ icon: Icon, onChange = () => null, onEnter, debounceDuration = 300, ...props }) {
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleInputChange = (e) => {
    if (e.target.value === '') {
      onChange(e); // Call immediately if input is cleared
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    debounce(onChange, debounceDuration)(e); // Call with delay for non-empty input
  };

  return (
    <TextField
      label="Search"
      onKeyDown={(e) => {
        if (e.key === 'Enter') void onEnter?.(String((e.target).value));
      }}
      {...props}
      onChange={handleInputChange}
      size="small"
      inputProps={{ style: { paddingBlock: '0.25rem', fontSize: '0.8rem', ...props.inputStyle } }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {Icon ? Icon : <Search style={{ height: 16 }} />}
          </InputAdornment>
        ),
      }}
    />
  );
}

export function GradientButton({ sx, ...props }) {
  return (<LoadingButton variant="contained" sx={{
    borderRadius: '10px',
    background: 'linear-gradient(180deg, #00bcd4 0%, #1da1f2 100%) !important',
    color: 'white',
    ...sx,
  }}
    {...props}
  />);
}

export const filterButtonStyles = {
  color: 'gray',
  minWidth: '0 !important',
  maxWidth: 'fit-content',
  fontSize: '0.75rem',
  padding: '0.1rem',
  paddingInline: '0.3rem',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: '#fff',
  boxShadow:
    'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px',
  '&:hover': {
    boxShadow:
      'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 4px 8px 0px',
    backgroundColor: '#fff',
  },
  '& .MuiButton-startIcon': {
    marginTop: '0.05rem',
    marginRight: '0.25rem',
    '.MuiSvgIcon-root': { width: '1rem', height: '1rem' }
  },
};