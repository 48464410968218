import Map from "./Map";
import { decode } from "@googlemaps/polyline-codec";
import { Box, Button, CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { useMap } from "react-leaflet";

import * as Leaflet from "leaflet";
import * as ReactLeaflet from "react-leaflet";
import CanvasMarkers from "./CanvasMarkers";
import { filterButtonStyles } from "../utils";
import { CropFreeOutlined } from "@mui/icons-material";
import { formatCurrency, formatDateTime } from "../../utils/formatter";

const tollRoads = [
  "Pacific Coast Highway",
  "Sunshine Skyway Bridge",
  "Blue Ridge Parkway",
  "Great River Road",
  "Overseas Highway",
  "Dalton Highway",
  "Highway 61",
  "Route 66",
  "Going-to-the-Sun Road",
  "Beartooth Highway",
  "Natchez Trace Parkway",
  "Route 1",
  "Million Dollar Highway",
  "Hana Highway",
  "Highway 101",
  "Route 20",
  "Highway 12",
  "Highway 6",
  "Highway 2",
  "Highway 50",
];

const decodePolyline = (polyline) => {
  const precision = 5;
  try {
    return [decode(JSON.parse('"' + polyline + '"'), precision)];
  } catch (_) {
    return [decode(polyline, precision)];
  }
};

const tollIconCash = new Leaflet.Icon({
  iconUrl: "https://cdn.tollguru.com/github/Wiki/calc/toll-cash.png",
  iconSize: [30, 30],
  iconAnchor: [5, 10],
});

const tollIconTag = new Leaflet.Icon({
  iconUrl: "https://cdn.tollguru.com/github/Wiki/calc/toll-tag.png",
  iconSize: [30, 30],
  iconAnchor: [5, 10],
});

const tollIcon = new Leaflet.Icon({
  iconUrl: "https://cdn.tollguru.com/github/Wiki/calc/green-pin-01.png",
  iconSize: [15, 30],
  iconAnchor: [5, 10],
});

function MapContent({
  tolls = [],
  gps = [],
  polyline,
  toggleGPS = false,
  ReactLeaflet: { Marker, Popup, Polyline },
}) {
  const map = useMap();

  const gpsIcon = new Leaflet.Icon({
    iconUrl: "/black-pin.svg",
    iconSize: [10, 20],
    iconAnchor: [5, 10],
  });

  const decodedPolyline = useMemo(
    () => (polyline?.polyline ? decodePolyline(polyline.polyline)?.[0] ?? [] : []),
    [polyline]
  );

  const bounds = useMemo(() => {
    if (decodedPolyline.length > 0) return decodedPolyline;
    if (gps.length > 0) return gps.map((g) => g.coord_array);
    if (tolls.length > 0) return tolls.map(({ entry_lat, entry_long }) => [entry_lat, entry_long]);
    return [];
  }, [decodedPolyline, tolls, gps]);

  const fitView = useCallback(() => map.fitBounds(bounds), []);
  const [visited, setVisited] = useState(false);
  useEffect(() => {
    if (visited || bounds.length === 0) return;
    setVisited(true);
    setTimeout(fitView, 750);
  }, [bounds, visited, map]);

  if (!polyline?.polyline && tolls.length === 0 && gps.length === 0) return <></>;

  return (
    <>
      {polyline?.polyline && <Polyline positions={decodePolyline(polyline.polyline)} />}

      {gps && gps.length > 0 && (
        <>
          <Marker
            position={gps[0].coord_array}
            icon={
              new Leaflet.Icon({
                iconUrl: "https://cdn.tollguru.com/github/Wiki/calc/from.png",
                iconSize: [25, 25],
              })
            }
          />
          <Marker
            position={gps[gps.length - 1].coord_array}
            icon={
              new Leaflet.Icon({
                iconUrl: "https://cdn.tollguru.com/github/Wiki/calc/to.png",
                iconSize: [25, 25],
              })
            }
          />
        </>
      )}

      {toggleGPS && gps && gps.length > 0 && (
        <CanvasMarkers
          map={map}
          markers={gps.map((m, i) => ({
            position: m.coord_array,
            icon: gpsIcon,
            popupContent: `<b>Index</b>: ${i}<br /><b>Timestamp</b>: ${formatDateTime(
              m.timestamp
            )}`,
          }))}
        />
      )}

      {tolls &&
        tolls?.length > 0 &&
        tolls?.map((toll, i) => (
          <Fragment key={`toll-${i}`}>
            <Marker
              icon={toll?.tag_cost ? tollIconTag : tollIconCash}
              key={`toll-entry-${i}`}
              position={[toll.entry_lat, toll.entry_long]}
              title={toll.toll_loc_name_start}
            >
              <Popup>
                <b>Toll Name</b>: {toll.toll_loc_name_start}
                {toll.entry_time && (
                  <>
                    <br />
                    <b>Entry time</b>: {formatDateTime(toll.entry_time)}
                  </>
                )}
                {toll.tag_primary_cost && (
                  <>
                    <br />
                    <b>GPS Toll</b>: {formatCurrency(toll.tag_primary_cost)}
                  </>
                )}
                <br />
                <b>Toll Road</b>: {tollRoads[Math.floor(Math.random() * tollRoads.length)]}
              </Popup>
            </Marker>

            {toll.exit_lat && toll.exit_lng && (
              <Marker
                icon={tollIcon}
                key={`toll-exit-${i}`}
                position={[toll.exit_lat, toll.exit_lng]}
                title={toll?.toll_loc_name_end || ""}
              >
                <Popup>
                  <b>Toll Name</b>: {toll.toll_loc_name_end}
                  {toll.exit_time && (
                    <>
                      <br />
                      <b>Exit time</b>: {formatDateTime(toll.exit_time)}
                    </>
                  )}
                </Popup>
              </Marker>
            )}
          </Fragment>
        ))}

      <Box sx={{ position: "absolute", top: 0, right: 0, padding: "1rem", zIndex: 2000 }}>
        <Tooltip title="Fit to view">
          <Button
            onClick={fitView}
            sx={{
              ...filterButtonStyles,
              borderRadius: "100%",
              padding: "0.5rem !important",
              aspectRatio: "1 / 1",
            }}
          >
            <CropFreeOutlined sx={{ height: 18, width: 18 }} />
          </Button>
        </Tooltip>
      </Box>
    </>
  );
}

export default function TollsMap({ tolls = [], gps = [], polyline, loading = false, ...props }) {
  // console.log("poly", polyline, "gps", gps)
  const [toggleGPS, setToggleGPS] = useState(false);

  // if (loading)
  //   return (
  //     <Stack
  //       direction="row"
  //       width="100%"
  //       height="100%"
  //       justifyContent="center"
  //       alignItems="center"
  //       padding="2rem"
  //     >
  //       <CircularProgress />
  //     </Stack>
  //   );

  return (
    <Stack direction="row" width="100%" height="100%" {...props}>
      <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
        <Map preferCanvas width="100%" height="100%" center={[42.149132, -87.85512]} zoom={12}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {({ TileLayer, ...mapProps }) => {
            return (
              <>
                {loading == true ? (
                  <Stack
                    direction="row"
                    width="100%"
                    height="70%"
                    justifyContent="center"
                    alignItems="center"
                    padding="1rem"
                  >
                    <CircularProgress />
                  </Stack>
                ) : (
                  <>
                    <TileLayer
                      attribution="&amp;copy Mapbox"
                      url="http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                      // tileSize={512}
                      // zoomOffset={-1}
                    />
                    <MapContent
                      polyline={polyline}
                      tolls={tolls}
                      gps={gps}
                      toggleGPS={toggleGPS}
                      ReactLeaflet={mapProps}
                    />
                  </>
                )}
              </>
            );
          }}
        </Map>
      </Box>
      {/* <Stack minWidth="150px" width="20%" paddingInline="0.5rem" paddingBlock="1rem" fontSize="0.75rem">
        <Tooltip title={`${gps?.length} points`}>
          <IconButton
            disableTouchRipple
            onClick={() => setToggleGPS((v) => !v)}
            sx={{ fontSize: '0.8rem', fontWeight: 'normal', color: 'initial', justifyContent: 'flex-start', padding: '0.5rem 0.25rem', gap: '0.5rem', alignItems: 'center' }}
          >
            <span style={{ borderRadius: '0.2rem', width: '10px', height: '10px', backgroundColor: '#1b1919a6' }} /> GPS Points
          </IconButton>
        </Tooltip>
      </Stack> */}
    </Stack>
  );
}
