import axios from "axios";
import { useEffect, useState } from "react";

const NewVehicleForm = ({ closeModal, openModal, vehicleData, setVehicles }) => {
  const [formData, setFormData] = useState({
    // unit: "101081",
    // vehicle_name: "VEH_1",
    // vehicle_make: "2000",
    // vehicle_model: "Ford F-150",
    // vehicle_model: "Ford F-150",
    // vin: "1G1YY36U075000000",
    // license_plate: "1ABC234",
    // license_plate_state: "Alabama",
    // vehicle_type: "2AxlesTruck",
    // vehicle_class: null,
    // axle_count: 2,
    // tire_count: 4,
    // vehicle_weight: 19500,
    // vehicle_height: "9.00",
    // tag_number: "",
    // tag_issuing_agency: "",
    // tag_type: "transponder",
  });

  const [loading, setLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const isFormChanged = Object.keys(formData).some((key) => {
      return formData[key] !== ""; // Check if any field has a value
    });
    setFormChanged(isFormChanged);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formChanged) {
      // If form hasn't changed, do not submit
      return;
    }

    const storedTokenResponse = JSON.parse(localStorage.getItem("token_response"));
    const motiveAccessToken = storedTokenResponse?.access_token;

    try {
      setLoading(true);
      const response = await axios.post(
        `https://geotab-backend.onrender.com/api/vehicles/newVehicle`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "motive-access-token": motiveAccessToken,
          },
        }
      );

      if (response.status === 200) {
        // Vehicle updated successfully
        console.log("Vehicle updated successfully");
        const refetchResponse = await axios.get(
          "https://geotab-backend.onrender.com/api/vehicles/sorted-vehicle",
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "motive-access-token": motiveAccessToken,
            },
          }
        );
        const newData = refetchResponse.data;
        console.log("New data:", newData);
        // Now you have the updated data, you can update your state with the new data
        // Example: setYourData(newData);

        closeModal();
      } else {
        // Handle error
        console.error("Error updating vehicle");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full mx-auto bg-white rounded-lg flex flex-col justify-between "
    >
      <div className="h-[80vh] overflow-auto pt-8 ">
        <h2 className="text-xl font-semibold mb-6">Add New Vehicle</h2>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="unit"
            defaultValue={vehicleData?.unit}
            id="unit"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="unit"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Unit
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="vehicle_name"
            defaultValue={vehicleData?.vehicle_name}
            id="vehicle_name"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_name"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Name
          </label>
        </div>
        {/* <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="number"
            name="eld_device_id"
            defaultValue={vehicleData?.eld_device.id}
            id="eld_device_id"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="eld_device_id"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            ELD Device Id
          </label>
        </div> */}
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="vin"
            defaultValue={vehicleData?.vin}
            id="vin"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vin"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            VIN
          </label>
        </div>{" "}
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="license_plate_state"
            defaultValue={vehicleData?.license_plate_state}
            id="license_plate_state"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="license_plate_state"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            License Plate State
          </label>
        </div>{" "}
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="license_plate"
            defaultValue={vehicleData?.license_plate}
            id="license_plate"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="license_plate_number"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            License Plate Number
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="vehicle_make"
            defaultValue={vehicleData?.vehicle_make}
            id="vehicle_make"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_make"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Make
          </label>
        </div>{" "}
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="vehicle_model"
            defaultValue={vehicleData?.vehicle_model}
            id="vehicle_model"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_model"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Model
          </label>
        </div>{" "}
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="vehicle_type"
            defaultValue={vehicleData?.vehicle_type}
            id="vehicle_type"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_type"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Type
          </label>
        </div>{" "}
        {/* <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="text"
            name="permanent_driver_id"
            id="permanent_driver_id"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="permanent_driver_id"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Permanent Driver Id
          </label>
        </div>{" "} */}
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="number"
            name="vehicle_class"
            defaultValue={vehicleData?.vehicle_class}
            id="vehicle_class"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="vehicle_class"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Class
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="number"
            name="axle_count"
            defaultValue={vehicleData?.axle_count}
            id="axle_count"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="axle_count"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Axle Count
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="number"
            name="tire_count"
            defaultValue={vehicleData?.tire_count}
            id="tire_count"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="tire_count"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Tire Count
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="string"
            name="tag_issuing_agency"
            defaultValue={vehicleData?.tag_issuing_agency}
            id="tag_issuing_agency"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="tag_issuing_agency"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Tag Issuing Agency
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            onChange={handleChange}
            type="string"
            name="tag_type"
            defaultValue={vehicleData?.tag_type}
            id="tag_type"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
            placeholder=" "
          />
          <label
            htmlFor="tag_type"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Tag Type
          </label>
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-5 group">
            <input
              onChange={handleChange}
              type="number"
              name="vehicle_height"
              defaultValue={vehicleData?.vehicle_height}
              id="vehicle_height"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
              placeholder=" "
            />
            <label
              htmlFor="vehicle_height"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Height (ft)
            </label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              onChange={handleChange}
              type="number"
              name="vehicle_weight"
              defaultValue={vehicleData?.vehicle_weight}
              id="vehicle_weight"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
              placeholder=" "
            />
            <label
              htmlFor="vehicle_weight"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Weight (lbs)
            </label>
          </div>
        </div>
        {/* <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative z-0 w-full mb-5 group">
            <input
              onChange={handleChange}
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              name="floating_phone"
              id="floating_phone"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_phone"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Phone number
            </label>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              onChange={handleChange}
              type="text"
              name="floating_company"
              id="floating_company"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_company"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Company (Ex. Google)
            </label>
          </div>
        </div> */}
      </div>
      <div className=" pt-3">
        <button
          type="submit"
          // disabled={loading}
          disabled={!formChanged || loading}
          className={`text-white  bg-tollguru-primary hover:bg-tollguru-primary focus:ring-4 focus:outline-none focus:ring-blue-300 flex justify-center gap-2 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center ${
            (!formChanged || loading) &&
            "cursor-not-allowed bg-tollguru-primary/60 hover:bg-tollguru-primary/60"
          }`}
        >
          {loading && (
            <div className=" flex items-center justify-center">
              <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white border-t-white border-r-2 border-r-white border-b-2 border-b-white"></div>
            </div>
          )}
          {loading ? "Adding Vehicle..." : "Add Vehicle"}
        </button>
      </div>
    </form>
  );
};

export default NewVehicleForm;
