import React, { useEffect, useState } from "react";
import "./App.css";
// Import your page components
import VehicleList from "./pages/VehicleList";
import Dashboard from "./pages/Dashboard";
import TripList from "./pages/TripList";
import TripSummary from "./pages/TripSummary";
import ThemeProvider from "./theme/ThemeProvider";
import Navbar from "./components/Navbar";
import Settings from "./pages/Settings";
import axios from "axios";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();
function App() {
  // State to manage the current view
  const [currentView, setCurrentView] = useState("dashboard");
  const [vehicleId, setVehicleId] = useState("");
  const [tripId, setTripId] = useState("");
  const [loading, setLoading] = useState(true)
  const [allVehicleName, setAllVehicleName] = useState(null);
  // Function to change the view

  const navigate = (view, id = "") => {
    setCurrentView(view);
    if (view === "trip-list") {
      setVehicleId(id);
    } else if (view === "trip-summary") {
      setTripId(id);
    }
  };

  useEffect(() => {
    (async () => {
      // if (loading === false) setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/vehicles/getAllVehicleName`);
        const rows = response.data;
        console.log("All vehicle Name", response);
        setAllVehicleName([
          {
            vehicle_name: "All Vehicle",
            vehicle_id: "all",
          },
          ...rows,
        ]);
      } catch (err) {
        console.log("Error fetching Vehicles Names:", err);
      } 
      setLoading(false)
    })();
  }, []);

  if (window) {
    let databaseName = window.location.pathname.slice(1, -1);
    let geotabCreds = JSON.parse(window.localStorage.getItem(`sTokens_${databaseName}`));
    axios.defaults.headers.common["geotab-database-name"] = "cariq";
    axios.defaults.headers.common["geotab-session-id"] = "wlWyrMHP7t5I5J-Ab18M5Q";
    axios.defaults.headers.common["geotab-username"] =  "maneesh@mapup.ai";
    axios.defaults.headers.common["geotab-server-name"] = "my.geotab.com" || window.location.hostname;
    axios.defaults.headers.common["market-place-id"] = 'geotab';

  }

  // Dynamically render the component based on currentView state
  const renderView = () => {
    switch (currentView) {
      case "vehicle-list":
        return <VehicleList navigate={navigate} allVehicleName={allVehicleName} />;
      case "trip-list":
        return vehicleId && <TripList vehicleId={vehicleId} allVehicleName={allVehicleName}/>;
      case "trip-summary":
        return <TripSummary />;
      case "vehicle-settings":
        return <Settings navigate={navigate} />;
      case "all-trips":
        return <TripList vehicleId={"all"} allVehicleName={allVehicleName} />;
      default:
        return <Dashboard navigate={navigate} />;
    }
  };

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
      <div className="bg-gray-200 min-h-screen font-sans">
        <Navbar navigate={navigate} loading={loading} currentView={currentView} />
        <div className="w-5/6 mx-auto">{renderView()}</div>
      </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
