import React, { useState, useEffect } from 'react';
import { Card } from "@mui/material";
import { formatFigure } from "../utils/formatter";
import CardWrapper from "./CardWrapper";
import LineGraph from "./reusableComponents/LineGraph";
import { createDateFilter } from './DateFilter';

export default function DailyTollFrequency({totalTollsPaidByDate}) {
  // State to store fetched data
  const [chartData, setChartData] = useState([]);
  const { date, DateFilter } = createDateFilter();
  useEffect(() => {
    // Async function to fetch data
      try {
        const formattedChartData = [{
          name: 'Tolls crossed',
          data: totalTollsPaidByDate?.map(({ date_val, total_tolls_paid }) => ({
            x: date_val, 
            y: Number(total_tolls_paid)
          })) ?? [],
          color: '#33C2FF'
        }];
    
        setChartData(formattedChartData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }

  }, [date,totalTollsPaidByDate]); // Empty dependency array means this effect runs once on mount

  return (
    <Card className="w-full">
      <CardWrapper title="Tolls crossed" action={<DateFilter />}>
        <LineGraph
          loading={false}
          chartData={chartData}
          valueFormatter={formatFigure}
        />
      </CardWrapper>
    </Card>
  );
}
