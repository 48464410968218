import React, { useEffect, useState, useLayoutEffect } from 'react';
import Leaflet from 'leaflet';
import * as ReactLeaflet from 'react-leaflet';

import styles from './Map.module.css';
import 'leaflet/dist/leaflet.css';

const { MapContainer } = ReactLeaflet;

function NewMarker(props) {
  return <ReactLeaflet.Marker icon={Leaflet.icon({ iconUrl: '/leaflet/images/marker-icon.png' })} {...props} />;
}

const Map = ({ children, className, ...rest }) => {
  let mapClassName = styles.map;

  const [unmountMap, setUnmountMap] = useState(false);

  useLayoutEffect(() => {
    setUnmountMap(false);
    return () => {
      setUnmountMap(true);
    };
  }, []);

  if (className) {
    mapClassName = `${mapClassName} ${className}`;
  }

  useEffect(() => {
    void (function init() {
      delete Leaflet.Icon.Default.prototype._getIconUrl;
      Leaflet.Icon.Default.mergeOptions({
        iconRetinaUrl: 'leaflet/images/marker-icon-2x.png',
        iconUrl: 'leaflet/images/marker-icon.png',
        shadowUrl: 'leaflet/images/marker-shadow.png',
      });
    })();
  }, []);

  if (!unmountMap) {
    return (
      <MapContainer preferCanvas={true} className={mapClassName} {...rest}>
        {children && children({ ...ReactLeaflet, Marker: NewMarker }, Leaflet)}
      </MapContainer>
    );
  }

  return null;
};

export default Map;
