import ReconnectingWebSocket from "reconnecting-websocket";
import { BehaviorSubject } from "rxjs";

export class OnCompleteJobSocket {
  constructor() {
    // Retrieve userDetails from localStorage
    let databaseName = window?.location?.pathname?.slice(1, -1);
    let tokenRes = JSON.parse(window.localStorage.getItem(`sTokens_${databaseName}`));
    let user_id = `${tokenRes?.userName}_${tokenRes?.database}`;

    if (tokenRes) {
      try {
        const userDetails = JSON.parse(tokenRes);
        const user_name = userDetails.userName; // Extracting user_id
        const database_name = userDetails.database; // Extracting user_id
        user_id = user_name + "_" + database_name;
      } catch (e) {
        console.error("Error parsing userDetails from localStorage", e);
      }
    }

    const wss = new ReconnectingWebSocket(
      `${process.env.REACT_APP_BACKEND_WEB_SOCKET}?user_id=${user_id || 'maneesh@mapup.ai_cariq'}`
    );
    this.contextStream = new BehaviorSubject(null);

    wss.addEventListener("open", () => {
      console.log("successfully connected to the server");
    });

    wss.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data); // Assuming the data is JSON formatted
        // console.log("websocket message", data);
        this.contextStream.next(data);
      } catch (e) {
        console.error("Error occurred while parsing event data", e);
      }
    });

    wss.addEventListener("error", (event) => {
      console.error("WebSocket error observed:", event);
    });

    wss.addEventListener("close", (event) => {
      console.log("WebSocket connection closed:", event);
    });

    this.wsConnection = wss;
  }

  wsConnection;
  contextStream;

  performCleanup() {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
  }

  // The getContextStream function appears to reference an undefined `tickStream`.
  // If you meant to use `contextStream`, here's how you might adjust that function:
  getContextStream() {
    return this.contextStream.asObservable(); // Return the contextStream as an Observable for subscribers
  }
}
