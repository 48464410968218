import { CardContent, CardHeader, Divider, Stack } from "@mui/material";

export default function CardWrapper({ title, children, action }) {
  return (
    <>
      <CardHeader
        title={title}
        titleTypographyProps={{ fontWeight: 600 }}
        sx={{ textAlign: "start", ".MuiCardHeader-action": { marginTop: "0" } }}
        action={
          <Stack direction="row" gap="0.5rem">
            {action}
          </Stack>
        }
      />
      <Divider />
      <CardContent
        sx={{
          flexGrow: 1,
          width: "100%",
          minHeight: "200px",
          padding: "0 !important",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </CardContent>
    </>
  );
}
