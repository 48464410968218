import {
  Box,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatDateTime } from '../../utils/formatter';
import Chart from 'react-apexcharts';


// type LineGraphProps = {
//   chartData: ApexAxisChartSeries,
//   loading?: boolean,
//   valueFormatter?: (val: number) => string,
//   dateFormatter?: (val: string) => string,
// }

export default function LineGraph({
  loading = false,
  chartData,
  valueFormatter,
  dateFormatter = (val) => formatDateTime(val, false)
}) {
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.error.main, theme.palette.primary.main],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    theme: {
      mode: theme.palette.mode,
    },
    stroke: {
      show: true,
      width: 2,
      colors: [theme.palette.primary.main],
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme.palette.divider,
    },
    xaxis: {
      tickAmount: 10,
      type: 'category',
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        formatter: (val) => dateFormatter?.(val) ?? '',
        show: true,
        style: {
          fontSize: '0.65rem',
          colors: theme.palette.text.secondary,
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      tickAmount: 6,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: (val) => valueFormatter?.(val) ?? '',
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      marker: {
        show: true,
      },
      y: {
        formatter: (val) => valueFormatter?.(val) ?? '',
      },
      theme: 'dark',
    },
  };

  if (loading) return <Box padding="2rem">
    <CircularProgress />
  </Box>;

  return (
    <Chart
      options={chartOptions}
      series={chartData}
      type="line"
      height={240}
      width={'100%'}
    />
  );
}
