export const currencySymbols = {
  ALL: "L",
  EUR: "€",
  ARS: "$",
  AUD: "A$",
  BYN: "Br",
  BAM: "KM",
  BGN: "Лв.",
  CAD: "Can$",
  CLP: "$",
  COP: "$",
  HRK: "kn",
  CZK: "Kč",
  DKK: "Kr.",
  HUF: "Ft",
  ISK: "kr",
  INR: "₹",
  MXN: "Mex$",
  MDL: "L",
  NZD: "$",
  MKD: "Ден",
  NOK: "kr",
  PEN: "S/.",
  PLN: "zł",
  USD: "$",
  RON: "lei",
  TRY: "₺",
  GBP: "£",
};
