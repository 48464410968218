import React, { useEffect, useState } from "react";
import { createDataTable } from "../components/reusableComponents/DataTable";
// import { trips } from "../dummyData/trips";
import { useNavigate, useParams } from "react-router-dom";
import MapTwoToneIcon from "@mui/icons-material/MapTwoTone";
import TableViewTwoToneIcon from "@mui/icons-material/TableViewTwoTone";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { trip } from "./trip-query";
import ModalWrapper from "../components/ModalWrapper";
import TollsMap from "../components/Map/TollsMap";
import TripSummary from "./TripSummary";
import { CachedTwoTone, InfoOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import {  useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { createDateFilter } from "../components/DateFilter";
import { OnCompleteJobSocket } from "../utils/websocket";
import ReactMap from "../components/Map/ReactMap";
import { formatCurrency, getFormatedDateTime } from "../utils/formatter";
import VehicleDropDown from "../components/reusableComponents/VehicleDropDown";

function GenerateReportModal({ open, onClose, isJobOngoing, setIsJobOngoing }) {
  const [isLoading, setIsLoading] = useState(false);
  // const [startDate, setStartDate] = useState(dayjs("2022-04-17"));
  // const [endDate, setEndDate] = useState(dayjs("2022-04-17"));
  const [startDate, setStartDate] = useState(dayjs().subtract(2, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const maxDateDifference = 84;

  const handleStartDateChange = (newStartDate) => {
    const newEndDate = newStartDate.add(maxDateDifference, "day");
    setStartDate(newStartDate);
    if (newEndDate.isBefore(endDate) || newEndDate.isSame(endDate)) {
      setEndDate(newEndDate);
    }
  };

  const handleEndDateChange = (newEndDate) => {
    if (newEndDate.diff(startDate, "day") > maxDateDifference) {
      setStartDate(newEndDate.subtract(maxDateDifference, "day"));
    }
    setEndDate(newEndDate);
  };

  const calculateApproximateTime = () => {
    const differenceInDays = endDate.diff(startDate, "day");
    const approximateTime = differenceInDays * 10; // Assuming each day takes 10 seconds
    return approximateTime;
  };

  const getTextColor = (time) => {
    return time > 60 ? "grey-500" : "grey-500";
  };

  const handleGenerateReport = async ({ isJobOngoing, setIsJobOngoing }) => {
    setIsLoading(true);

    const url = `${
      process.env.REACT_APP_BASE_URL
    }/trips/generateReport?application_type=geotab&start_date=${startDate.format(
      "YYYY-MM-DD"
    )}&end_date=${endDate.format("YYYY-MM-DD")}`;
    try {
      const response = await axios.post(url);

      console.log("Report generated successfully");
      onClose();
      setIsJobOngoing(true);
    } catch (error) {
      console.error("Error generating report:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper open={open} onClose={onClose}>
      {isLoading ? (
        <Box sx={{ padding: "2rem", display: "grid", placeContent: "center" }}>
          <CircularProgress sx={{ color: "#1da1f2" }} />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "4px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <div className="flex p-4">
            <div className="flex justify-between  w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="bg-gray-50 w-full">
                  <div className="flex flex-wrap justify-between max-h-[60vh] overflow-y-auto">
                    <div className="md:w-1/2">
                      <h3 className="p-6 text-center font-bold">Start Date</h3>
                      <DateCalendar
                        value={startDate}
                        onChange={handleStartDateChange}
                        maxDate={endDate.subtract(1, "day")}
                      />
                    </div>
                    <div className="md:w-1/2">
                      <h3 className="p-6 text-center font-bold">End Date</h3>
                      <DateCalendar
                        value={endDate}
                        onChange={handleEndDateChange}
                        minDate={startDate.add(1, "day")}
                      />
                    </div>
                  </div>
                </div>
              </LocalizationProvider>
            </div>
          </div>

          <div className="flex justify-center mb-4">
            <div>
              <button
                className="my-2 border-solid border-[1.5px] bg-tollguru-primary text-base text-gray-100 focus-visible:shadow-outline-indigo px-5 py-2 rounded-full font-normal"
                type="button"
                onClick={handleGenerateReport}
              >
                Generate Toll Report
              </button>
            </div>
          </div>
          {/* Display approximate time with color based on estimated time */}
          {/* <div
            className={`text-center text-${getTextColor(
              calculateApproximateTime()
            )}`}
          >
            Approximate time to complete: {calculateApproximateTime()} seconds
          </div> */}
          {calculateApproximateTime() != 0 && (
            <div
              className={`text-center text-sm mb-1 font-bold text-${getTextColor(
                calculateApproximateTime()
              )}`}
            >
              Tentative time to generate report ~ {calculateApproximateTime()} mins
            </div>
          )}
        </Box>
      )}
    </ModalWrapper>
  );
}

const fetchGpsTracksDetails = async (tripId) => {
  const storedTokenResponse = JSON.parse(localStorage.getItem("token_response"));
  const samsaraAccessToken = storedTokenResponse?.access_token;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/trips/tripResponse/${tripId}?application_type=geotab`
    );
    return response.data;
  } catch (error) {
    return {};
  }
};
export function InfoText({ tooltip, children, showTooltip = true, ...props }
) {
  return (
    <div {...props} style={{ display: 'flex', alignItems: 'center', gap: '0.05rem', ...props.style }}>
      {showTooltip &&
        <Tooltip title={tooltip}>
          <InfoOutlined sx={{ height: '0.9rem', marginTop: '0.1rem' }} />
        </Tooltip>
      }
      {children}
    </div>
  );
}

function MapModal({ tripDetails, open, onClose, mapLoading, setShowTolls, showTolls }) {
  return (
    <ModalWrapper open={open} onClose={onClose} width={"80%"}>
      {mapLoading || Object.keys(tripDetails).length === 0 ? (
        <Box sx={{ padding: "2rem", display: "grid", placeContent: "center" }}>
          <CircularProgress sx={{ color: "#1da1f2" }} />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#fff",
            // padding: "20px",
            // paddingTop: "0.75rem",
            borderRadius: "4px",
            outline: "none",
            // height: "60vh",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <div className="flex">
            {/* <Map
              tolls={tripDetails.route.tolls}
              polyline={tripDetails.route.polyline}
              coordinates={tripDetails.coordinates}
            /> */}
            {console.log("trip detail", tripDetails)}
            {/* <TollsMap
              sx={{ height: "80vh" }}
              polyline={tripDetails.route.polyline}
              // gps={tripDetails.coordinates}
              tolls={tripDetails.route.tolls}
              loading={mapLoading}
            /> */}
            <ReactMap
              tolls={tripDetails.route.tolls}
              polyline={tripDetails.route.polyline}
              coordinates={tripDetails.coordinates}
            />
            <div className="p-4">
              <button
                className="bg-tollguru-primary text-white  rounded-lg px-5 py-2 w-44 "
                onClick={(e) => {
                  e.stopPropagation();

                  // setSelectedTrip({
                  //   ...selectedTrip,
                  //   api_key: item.row.original.api_key,
                  //   request_id: item.row.original.request_id,
                  //   request_date: item.row.original.download_time,
                  // });
                  setShowTolls(true);
                }}
              >
                View Tolls
              </button>
              {/* {showTolls ? <TollsModal /> : null} */}
              <ModalWrapper open={showTolls} onClose={() => setShowTolls(false)} width={"80%"}>
                <TripSummary tolls={tripDetails.route.tolls} />
              </ModalWrapper>
            </div>
          </div>
        </Box>
      )}
    </ModalWrapper>
  );
}

const TripList = ({ vehicleId, allVehicleName }) => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

  const [loading, setLoading] = useState(false);
  // const [mapLoading, setMapLoading] = useState(false);
  const [tripId, setTripId] = useState(null);
  const [hasTrips, setHasTrips] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showTolls, setShowTolls] = useState(false);
  const [showGenerateReportModal, setShowGenerateReportModal] = useState(false);
  const [isJobOngoing, setIsJobOngoing] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({
    unit: "",
    trip_id: "",
    trip_date: "",
  });
  // dropdown
  // const [allVehicleName, setAllVehicleName] = useState([]);
  const [selected, setSelected] = useState({
    vehicle_name: "",
    vehicle_id: "all",
  });
  // const [selectedTripRes, setSelectedTripRes] = useState({});
  const [selectedTolls, setSelectedTolls] = useState([]);
  const [backendPageNo, setBackendPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  // const [hasNextPage, setHasNextPage] = useState(true);
  const [nextReportText, setNextReportText] = useState("");
  // let filteredTrips = trips.filter((trip) => trip.unit === vehicleId);
  const { date, DateFilter } = createDateFilter();

  console.log("date",date);
  const fetchTrips = async ({ pageParam = 1, vehicleId, startDate, endDate }) => {
    let reqUrl = `${baseUrl}/trips?application_type=geotab&page=${pageParam}&limit=150`;
  
    if (vehicleId && vehicleId !== "all") {
      reqUrl += `&vehicle_id=${vehicleId}`;
    }
  
    if (startDate && endDate) {
      reqUrl += `&start_date=${startDate}&end_date=${endDate}`;
    }
  
    const response = await axios.get(reqUrl);
    const rows = await response.data;
    if(!hasTrips && rows.trips.length>0) setHasTrips(true);
    // Assuming getVehicleNameById is synchronous or that the necessary info is already available
    rows.trips = rows.trips.map(t => ({
      ...t,
      vehicle_name: getVehicleNameById(t.vehicle_id)
    }));
  console.log("Rows Trips",rows.trips);
    return {
      trips: rows.trips, // The data for the current page
      nextPage: rows.nextPage, // Assuming your API returns this for determining the next page number
      total: rows.totalItems // Total number of items, if you need this for UI
    };
  };
  

  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['trips', date.date.start, date.date.end, selected.vehicle_id],
    queryFn: ({ pageParam = 1 }) => fetchTrips({ pageParam, vehicleId:selected.vehicle_id, startDate:date.date.start, endDate: date.date.end}),
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    staleTime: Infinity, // Adjust based on your application's needs
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours, for example
  });
  useEffect(() => {
    const calculateNextReportTime = () => {
      const now = new Date();
      // Convert now to UTC
      const utcNow = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes()
        )
      );

      let nextReportUtc = new Date(utcNow);

      const morningReportUTCHour = 0; // 12 AM UTC time for morning report
      const eveningReportUTCHour = 12; // 12 PM UTC time for evening report

      if (utcNow.getUTCHours() >= eveningReportUTCHour) {
        // If it's past 12 PM UTC, set next report for 12 AM UTC the next day
        nextReportUtc.setUTCDate(utcNow.getUTCDate() + 1);
        nextReportUtc.setUTCHours(morningReportUTCHour, 0, 0, 0); // Set to 12 AM UTC
      } else if (utcNow.getUTCHours() < morningReportUTCHour) {
        // If it's before 12 AM UTC, set next report for the same day at 12 AM UTC
        nextReportUtc.setUTCHours(morningReportUTCHour, 0, 0, 0);
      } else {
        // Otherwise, set next report for 12 PM UTC the same day
        nextReportUtc.setUTCHours(eveningReportUTCHour, 0, 0, 0);
      }

      // Calculate the difference in milliseconds
      const diffMs = nextReportUtc - utcNow;
      // Convert the difference to hours, rounding up
      const diffHours = Math.ceil(diffMs / (3600 * 1000));

      setNextReportText(`Tolls will be updated in ${diffHours}hrs`);
    };

    calculateNextReportTime();

    // Update this every minute to keep the countdown accurate
    const intervalId = setInterval(calculateNextReportTime, 60 * 1000);

    // Clean up the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []);
  function getVehicleNameById(id){
    const d = allVehicleName.filter((v)=>v.vehicle_id==id)
    if(d.length>0) return d[0].vehicle_name;
    return id;
  }
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await axios.get(`${baseUrl}/vehicles/getAllVehicleName`);
  //       const rows = response.data;
  //       console.log("All vehicle Name", response);
  //       setAllVehicleName([
  //         {
  //           vehicle_name: "All Vehicle",
  //           vehicle_id: "all",
  //         },
  //         ...rows,
  //       ]);
  //     } catch (err) {
  //       console.log("Error fetching Vehicles Names:", err);
  //     }
  //   })();
  // }, []);

  const sortByDateAndFormatTrips = (trips=[]) => {
    trips.sort((a, b) => {
      let dateA = new Date(a.start_date);
      let dateB = new Date(b.start_date);

      return dateB - dateA;
    });

    return trips.map((trip) => ({
      ...trip,
      gpsToll: trip.toll_data.route.costs.tagAndCash,
      tollCrossed: trip.toll_data.route.tolls.length,
      distance: trip.toll_data.route.distance.text,
      start: getFormatedDateTime(trip.start_date),
      end: getFormatedDateTime(trip.end_date),
      expressLaneToll:trip.toll_data.route.costs.expressLanes?.tagCost ?? 0
    }))
  }
  const DataTable = createDataTable({
    rows: sortByDateAndFormatTrips(data?.pages?.flatMap(i=>i.trips)),
    columns: ({ column }) => [
      column("vehicle_name", {
        header: "Vehicle Name",
        meta: { export: true },
        enableSorting: false,
        cell: (item) => {
          return (
            <Box
              sx={{
                fontSize: "14px !important",
                // paddingLeft: "5px !important",
              }}
            >
             
                <Tooltip
                  title={`${item.row.original.vehicle_name}(${item.row.original.geotab_vehicle_id})`}
                >
                  {`${item.row.original.vehicle_name.substring(0, 25)} ${item.row.original.vehicle_name.length>25?'...':''}`}
                </Tooltip>
            
             
            </Box>
          );
        },
      }),
      column("gpsToll", {
        header: "GPS Toll",
        cell: (item) => {
          return (
            <Box
              sx={{
                fontSize: "14px !important",
                // paddingLeft: "5px !important",
              }}
            >
              {formatCurrency(item.row.original.gpsToll)}
            </Box>
          );
        },
        type: "currency",
        meta: { align: "right", export: true },
      }),
      column('expressLaneToll', {
        header: 'Express Toll Cost',
        headerRender: (val) => (
          <InfoText
            tooltip="This is an average estimated toll cost if you travel on express lanes."
            className="MuiDataGrid-columnHeaderTitle"
          >
            {val}
          </InfoText>
        ),
        cell: (item) => {
          return (
            <Box
              sx={{
                fontSize: "14px !important",
                // paddingLeft: "5px !important",
              }}
            >
              {formatCurrency(item.row.original.expressLaneToll)}
            </Box>
          );
        },
        type: 'currency',
        meta: { align: "right", export: true },
      }),
      column(
        "view_map",
        {
          header: "View Map",
          cell: (item) => (
            <Tooltip title="View tolls on map" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: (theme) => theme.colors.primary.lighter,
                  },
                  color: (theme) => theme.colors.primary.main,
                }}
                color="inherit"
                size="small"
                onClick={(e) => {
                  console.log("Original", item.row.original);
                  e.stopPropagation();
                  // setMapLoading(true);
                  setTripId(item.row.original._id);
                  setSelectedTolls(item.row.original.tolls);

                  setSelectedTrip({
                    trip_id: item.row.original._id,
                    trip_date: item.row.original.trip_date,
                    unit: item.row.original.unit,
                  });
                  setShowMap(true);
                }}
              >
                <MapTwoToneIcon />
              </IconButton>
            </Tooltip>
          ),
          meta: { export: false, align: "center" },
          enableSorting: false,
        },
        (row) => row
      ),
      column("start", { header: "Start Time" }), // Assuming you have a formatDateTime function
      column("end", { header: "End Time" }),
      column("tollCrossed", { header: "Toll Crossed", meta: { align: "right", export: true } }),
      // New columns added below
      column("distance", { header: "Distance", meta: { align: "right", export: true } }),
      column("request_id", { header: "Request ID", meta: { export: false } }),
    ],
  });

  useEffect(() => {
    const ws = new OnCompleteJobSocket();
    const subscription = ws.contextStream.subscribe({
      next: (data) => {
        if (data) {
          // Check if the status is "ongoing" to set isLoading
          console.log("connection sttream", data);
          setIsJobOngoing(data.status === "ongoing");
        }
      },
      error: (err) => console.error(err),
      complete: () => console.log("Stream completed"),
    });

    return () => {
      setIsJobOngoing(false);
      subscription.unsubscribe();
      ws.performCleanup();
    };
  }, []);

  const { data: tripDetails, isLoading: mapLoading } = useQuery({
    queryKey: ['tripDetails', tripId],
    queryFn: () => fetchGpsTracksDetails(tripId),
  });

  return (
    <>
      {isJobOngoing && (
        <div className="  flex items-center justify-center z-50 mb-6 mt-6">
          <div className="text-center p-5 bg-white rounded-lg border shadow-lg">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-tollguru-primary mb-4 mx-auto"></div>
            <h2 className="text-lg font-semibold">Generating Report...</h2>
            <p className="text-gray-600">
              Your report is being generated and might complete in 5-10 minutes.
            </p>
          </div>
        </div>
      )}
      <div className="flex flex-row justify-between items-center ">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl pl-4 pt-6 pb-2 font-medium">Trip Overview</h1>
        </div>

        <GenerateReportModal
          isJobOngoing={isJobOngoing}
          setIsJobOngoing={setIsJobOngoing}
          open={showGenerateReportModal}
          onClose={() => setShowGenerateReportModal(false)}
        />
        {/* <MapModal
          open={showMap}
          onClose={() => setShowMap(false)}
          selectedTrip={selectedTrip}
          selectedTolls={selectedTolls}
          setShowTolls={setShowTolls}
          showTolls={showTolls}
        /> */}
        <MapModal
          tripDetails={tripDetails}
          open={showMap}
          onClose={() => setShowMap(false)}
          mapLoading={mapLoading}
          selectedTrip={selectedTrip}
          setShowTolls={setShowTolls}
          showTolls={showTolls}
        />

        <div className=" flex md:mb-0 justify-center border-solid border-[1.5px]">
          {/* <div>
                        <DynamicDateFilter />
                      </div> */}

          {/* {!isJobOngoing && (!hasTrips && !isFetching) && (
            <button
              disabled={isJobOngoing}
              className={`flex text-base w-full rounded-full min-w-[200px] justify-center text-gray-100 focus-visible:shadow-outline-indigo px-5 py-1 font-normal ${
                isJobOngoing ? "cursor-not-allowed opacity-50 bg-primary" : "bg-tollguru-primary"
              }`}
              type="button"
              onClick={() => setShowGenerateReportModal(!showGenerateReportModal)}
            >
              <CachedTwoTone /> Calculate Tolls
            </button>
          )} */}
        </div>
      </div>
      <DataTable
        infinitePagination
        initialState={{
          pagination: {
            pageIndex: currentPage - 1,
            pageSize: rowsPerPage, // Set the initial page number to 2
          },
        }}
        loading={isLoading || isFetching}
        onRowClick={() => {}}
        setRowsPerPage={setRowsPerPage}
        onPageChange={async (isLastPage, requestingNextPage, setPageIndex, page) => {
          if (requestingNextPage && isLastPage) {
            await fetchNextPage() // Increment page number
            setPageIndex(page);
            // setCurrentPage(currentPage+1)
          } else {
            setPageIndex(page);
          }
          setCurrentPage(page + 1);
        }}
        cursor_pointer={true}
      >
        <div className="grid grid-cols-3 w-full items-center">
        {/* <div className="flex justify-between w-full items-center"> */}
            {(!isJobOngoing) ? (
              <div
                className={`rounded-full text-gray-800 focus-visible:shadow-outline-indigo px-5 py-1 font-normal cursor-not-allowed italic bg-primary/50 flex text-sm items-center gap-1 max-w-[24rem]`}
              >
                <InfoOutlined sx={{ fontSize: "20px" }} />
                {nextReportText}
              </div>
            ):<div></div>}
            <VehicleDropDown
              allVehicleName={allVehicleName}
              selected={selected}
              setSelected={setSelected}
              setCurrentPage={setCurrentPage}
            />
            {/* </div> */}
               <div className="flex flex-row-reverse gap-2 w-full items-center">
               <DataTable.Export filename={"trip-overview.csv"} />
            <DateFilter />
        
          {/* <DataTable.Search /> */}
         
            </div>
        </div>
      </DataTable>
    </>
  );
};

export default TripList;
