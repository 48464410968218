import { Card } from "@mui/material";
import { formatCurrency, formatFigure } from "../utils/formatter";
import CardWrapper from "./CardWrapper";
import LineGraph from "./reusableComponents/LineGraph";
import { useEffect, useState } from "react";
import { createDateFilter } from "./DateFilter";

// const data = {
//   totalTagAndCashCostByDate: [
//     { date_val: "2023-10-30T00:00:00.000Z", total_tag_and_cash_cost: 260.9 },
//     { date_val: "2023-11-02T00:00:00.000Z", total_tag_and_cash_cost: 565.88 },
//     { date_val: "2023-11-09T00:00:00.000Z", total_tag_and_cash_cost: 300.14 },
//     { date_val: "2023-11-13T00:00:00.000Z", total_tag_and_cash_cost: 282.94 }
//   ]
// }

// const chartData = [{
//   name: 'Total Transactions',
//   data: data.totalTagAndCashCostByDate.map(({ date_val, total_tag_and_cash_cost }) => ({ x: date_val, y: Number(total_tag_and_cash_cost) })) ?? [],
//   color: '#33C2FF',
// }]

export default function DailyTollCost({ totalTagAndCashCostByDate}) {
  // console.log(chartData);
  const [dailyTollCostData, setDailyTollCostData] = useState([]);
  const { date, DateFilter } = createDateFilter();
  useEffect(() => {
      try {
        // Prepare the request options for a POST request with JSON body
        const chartData = [
          {
            name: "GPS tolls",
            data: totalTagAndCashCostByDate?.map(({ date_val, total_tag_and_cash_cost }) => ({
                x: date_val,
                y: Number(total_tag_and_cash_cost),
              })) ?? [],
            color: "#33C2FF",
          },
        ];
        setDailyTollCostData(chartData); // Update state with the fetched data
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
      }

    // fetchData();
  }, [date,totalTagAndCashCostByDate]);

  return (
    <Card className="w-full">
      <CardWrapper title="GPS tolls" action={<DateFilter />}>
        <LineGraph loading={false} chartData={dailyTollCostData} valueFormatter={formatCurrency} />
      </CardWrapper>
    </Card>
  );
}
