import { Card, CardContent, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";

import {
  calculatePercentage,
  formatCurrency,
  formatFigure,
  formatNumber,
} from "../utils/formatter";
import { useEffect, useMemo, useState } from "react";
import { createDateFilter } from "./DateFilter";
import DailyTollCost from "./DailyTollCost";
import DailyTollFrequency from "./DailyTollFrequency";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const textGradientStyles = {
  backgroundcolor: "primary",
  backgroundImage: "linear-gradient(45deg, #1da1f2 , #00BCD4)",
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

export default function DashboardOverview() {
  // const [dashboard, setDashboard] = useState({});
  // const [loading, setLoading] = useState(false);
  // const { date } = createDateFilter();
  const fetchDashboardData = async (date) => {
    let url = `${process.env.REACT_APP_BASE_URL}/trips/tripSummary`;
    if (date?.date?.start && date?.date?.end) {
      url += `?start_date=${date.date.start.split("T")[0]}&end_date=${date.date.end.split("T")[0]}`;
    }
    const response = await axios.get(url);
    return response.data.data;
  };

  const { date } = createDateFilter();

  const { data: dashboard, isLoading: loading } = useQuery({
    queryKey: ["dashboardData", date],
    queryFn: () => fetchDashboardData(date),
    staleTime: 5 * 60 * 1000,
  });

  console.log(dashboard?.totalTollTransactionByDate);
  return (
    <>
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          {/* First row with a single full-width Skeleton */}
          <div style={{ display: "flex", width: "100%" }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={100}
              style={{
                borderRadius: "8px",
                backgroundImage:
                  "linear-gradient(90deg, rgba(240,240,240,0.15) 25%, rgba(240,240,240,0.25) 50%, rgba(240,240,240,0.15) 75%)",
              }}
            />
          </div>

          {/* Second row with two equal-width Skeletons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "250px",
              gap: "14px",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="50%"
              height={"100%"}
              style={{
                borderRadius: "8px",
                backgroundImage:
                  "linear-gradient(90deg, rgba(240,240,240,0.2) 25%, rgba(240,240,240,0.25) 50%, rgba(240,240,240,0.2) 75%)",
              }}
            />
            <Skeleton
              variant="rectangular"
              width="50%"
              height={"100%"}
              style={{
                backgroundImage:
                  "linear-gradient(90deg, rgba(240,240,240,0.2) 25%, rgba(240,240,240,0.25) 50%, rgba(240,240,240,0.2) 75%)",
              }}
            />
          </div>
        </div>
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <Card
            elevation={3}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <h1 className="text-xl  font-medium  px-4 py-3 pb-0 bg-white w-full  rounded-tl-lg rounded-tr-lg">
          Total Toll Spend Overview
        </h1> */}
            <CardContent sx={{ paddingInline: "1rem" }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    display: "grid",
                    placeItems: "center",
                    gridTemplateRows: "1fr 1fr",
                    gridTemplateColumns: "1fr 1fr",
                  },
                  [theme.breakpoints.down("sm")]: {
                    display: "flex",
                    flexDirection: "column",
                  },
                  gap: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                })}
              >
                <Stack
                  sx={(theme) => ({
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      gap: "0.25rem",
                    },
                  })}
                >
                  <Typography
                    sx={textGradientStyles}
                    variant="body1"
                    lineHeight="1.4"
                    fontSize="1.65rem"
                    fontWeight="bold"
                  >
                    {loading ? "loading..." : formatFigure(dashboard?.vehicleCount ?? 0)}
                  </Typography>
                  <Typography variant="subtitle1" lineHeight="1" fontSize="1rem" fontWeight="light">
                    Vehicles
                  </Typography>
                </Stack>
                <Stack
                  sx={(theme) => ({
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      gap: "0.25rem",
                    },
                  })}
                >
                  <Typography
                    sx={textGradientStyles}
                    color="#1da1f2"
                    variant="body1"
                    lineHeight="1.4"
                    fontSize="1.65rem"
                    fontWeight="bold"
                  >
                    {loading ? "loading..." : "$" + formatNumber(dashboard?.totalTollCost ?? 0)}
                  </Typography>
                  <Typography variant="subtitle1" lineHeight="1" fontSize="1rem" fontWeight="light">
                    GPS Tolls
                  </Typography>
                </Stack>
                <Stack
                  sx={(theme) => ({
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      gap: "0.25rem",
                    },
                  })}
                >
                  <Typography
                    sx={textGradientStyles}
                    color="#1da1f2"
                    variant="body1"
                    lineHeight="1.4"
                    fontSize="1.65rem"
                    fontWeight="bold"
                  >
                    {/* {dashboard?.total_trips} */}
                    {loading ? "loading..." : formatNumber(dashboard?.transactionCount ?? 0)}
                  </Typography>
                  <Typography variant="subtitle1" lineHeight="1" fontSize="1rem" fontWeight="light">
                    Transaction Count
                  </Typography>
                </Stack>
                <Stack
                  sx={(theme) => ({
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      gap: "0.25rem",
                    },
                  })}
                >
                  <Typography
                    sx={{
                      ...textGradientStyles,
                    }}
                    color="#1da1f2"
                    variant="body1"
                    lineHeight="1.4"
                    fontSize="1.65rem"
                    fontWeight="bold"
                  >
                    {loading ? "loading..." : formatNumber(dashboard?.totalDistance ?? 0)}
                  </Typography>
                  <Typography variant="subtitle1" lineHeight="1" fontSize="1rem" fontWeight="light">
                    Toll Miles
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      )}
      {!loading && (
        <div className="flex gap-4">
          <DailyTollCost totalTagAndCashCostByDate={dashboard?.totalTagAndCashCostByDate} />
          <DailyTollFrequency totalTollsPaidByDate={dashboard?.totalTollTransactionByDate} />
        </div>
      )}
    </>
  );
}
