import { Box, Button, Modal, Paper, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ModalWrapper({ children, open, onClose,width }) {
  if (!open) return <></>;

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        zIndex:'100010',
        paddingTop:'60px'
      }}
      open={open}
    >
      <Box
        sx={{
          backgroundColor: '#f6f8fa',
          padding: '20px',
          paddingTop: '0.75rem',
          borderRadius: '4px',
          outline: 'none',
          width: width,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Stack direction="row" sx={{ alignSelf: 'flex-end' }}>
          <Button
            sx={{
              color: '#FF1943',
              borderRadius: '4px !important',
              padding: '0.1rem !important', minWidth: '0 !important',
              '&:hover': {
                backgroundColor: 'rgba(255, 25, 67, 0.1) !important'
              }
            }}
            variant="text" onClick={onClose}
          >
            <CloseIcon sx={{ height: 20 }} />
          </Button>
        </Stack>
        <Box component={Paper} sx={{ overflow: 'hidden' }}>
          {children}
        </Box>
      </Box>
    </Modal>
  );

}

export default ModalWrapper;
