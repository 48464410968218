import {  useEffect, useRef, useState } from "react";


const vehicleData = [
    {
        "category": "Car, SUV or Pickup Truck",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesAuto",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Car, SUV or Pickup truck"
            },
            {
                "type": "3AxlesAuto",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Car, SUV or Pickup truck towing 1-axle trailer"
            },
            {
                "type": "4AxlesAuto",
                "efficiency": {
                    "city": 17.2,
                    "highway": 22.1
                },
                "description": "Car, SUV or Pickup truck towing 2-axle trailer"
            },
            {
                "type": "2AxlesDualTire",
                "efficiency": {
                    "city": 17.2,
                    "highway": 22.1
                },
                "description": "SUV or Pickup, 4 tires on rear"
            },
            {
                "type": "3AxlesDualTire",
                "efficiency": {
                    "city": 16.7,
                    "highway": 21.6
                },
                "description": "SUV or Pickup, 4 tires on rear, 1-axle trailer"
            },
            {
                "type": "4AxlesDualTire",
                "efficiency": {
                    "city": 16.2,
                    "highway": 21.1
                },
                "description": "SUV or Pickup, 4 tires on rear, 2-axle trailer"
            }
        ]
    },
    {
        "category": "Truck",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesTruck",
                "efficiency": {
                    "city": 13,
                    "highway": 18
                },
                "description": "Truck - 2 Axles"
            },
            {
                "type": "3AxlesTruck",
                "efficiency": {
                    "city": 7.3,
                    "highway": 9.4
                },
                "description": "Truck - 3 Axles"
            },
            {
                "type": "4AxlesTruck",
                "efficiency": {
                    "city": 7.3,
                    "highway": 9.4
                },
                "description": "Truck - 4 Axles"
            },
            {
                "type": "5AxlesTruck",
                "efficiency": {
                    "city": 6.4,
                    "highway": 8.2
                },
                "description": "Truck - 5 Axles"
            },
            {
                "type": "6AxlesTruck",
                "efficiency": {
                    "city": 5.8,
                    "highway": 7.4
                },
                "description": "Truck - 6 Axles"
            },
            {
                "type": "7AxlesTruck",
                "efficiency": {
                    "city": 5.3,
                    "highway": 6.9
                },
                "description": "Truck - 7 Axles"
            },
            {
                "type": "8AxlesTruck",
                "efficiency": {
                    "city": 4.8,
                    "highway": 6.4
                },
                "description": "Truck - 8 Axles"
            },
            {
                "type": "9AxlesTruck",
                "efficiency": {
                    "city": 4.3,
                    "highway": 5.9
                },
                "description": "Truck - 9 Axles"
            }
        ]
    },
    {
        "category": "Bus",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesBus",
                "efficiency": {
                    "city": 7.2,
                    "highway": 9.2
                },
                "description": "Bus - 2 Axles"
            },
            {
                "type": "3AxlesBus",
                "efficiency": {
                    "city": 6.2,
                    "highway": 8.2
                },
                "description": "Bus - 3 Axles"
            }
        ]
    },
    {
        "category": "Motorcycle",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesMotorcycle",
                "efficiency": {
                    "city": 44,
                    "highway": 56.4
                },
                "description": "Motorcycle"
            },
            {
                "type": "3AxlesMotorcycle",
                "efficiency": {
                    "city": 44,
                    "highway": 56.4
                },
                "description": "Motorcycle towing trailer"
            }
        ]
    },
    {
        "category": "RV",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesRv",
                "efficiency": {
                    "city": 17.1,
                    "highway": 21.9
                },
                "description": "RV"
            },
            {
                "type": "3AxlesRv",
                "efficiency": {
                    "city": 16.1,
                    "highway": 20.9
                },
                "description": "RV towing 1-axle trailer"
            },
            {
                "type": "4AxlesRv",
                "efficiency": {
                    "city": 15.1,
                    "highway": 18.9
                },
                "description": "RV towing 2-axle trailer"
            }
        ]
    },
    {
        "category": "Rideshare",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesTNC",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Rideshare - Car, SUV or Pickup truck "
            },
            {
                "type": "2AxlesTNCPool",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Rideshare Pool - Car, SUV or Pickup truck "
            }
        ]
    },
    {
        "category": "Taxi",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesTaxi",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Taxi - Car, SUV or Pickup truck "
            },
            {
                "type": "2AxlesTaxiPool",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Taxi Pool - Car, SUV or Pickup truck "
            }
        ]
    },
    {
        "category": "Carpool",
        "open": false,
        "vehicle": [
            {
                "type": "Carpool2",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Carpool (2 occupants)"
            },
            {
                "type": "Carpool3",
                "efficiency": {
                    "city": 23.4,
                    "highway": 30
                },
                "description": "Carpool (3+ occupants)"
            }
        ]
    },
    {
        "category": "Electric Vehicles",
        "open": false,
        "vehicle": [
            {
                "type": "2AxlesEV",
                "efficiency": {
                    "city": 31,
                    "highway": 35
                },
                "description": "EV"
            },
            {
                "type": "3AxlesEV",
                "efficiency": {
                    "city": 31,
                    "highway": 35
                },
                "description": "EV towing 1-axle trailer"
            },
            {
                "type": "4AxlesEV",
                "efficiency": {
                    "city": 30,
                    "highway": 34
                },
                "description": "EV towing 2-axle trailer"
            }
        ]
    }
  ]
 export function getDescription(vehicle_id) {
    for (const categoryData of vehicleData) {
        for (const vehicle of categoryData.vehicle) {
            if (vehicle.type === vehicle_id) {
                return vehicle.description;
            }
        }
    }
    return "Description not found for this vehicle ID";
}
const VehicleInput = (props) => {
  const [dropDown, setDropDown] = useState(false);
  const [vehicles, setVehicles] = useState(vehicleData);

  
  function toggleDoubleDropdown(index){
   setVehicles([...vehicles.slice(0,index), {...vehicles[index],open:!vehicles[index].open},...vehicles.slice(index+1)]);
  }
  return (
    <>
      {/* <p className="leading-7 text-sm text-[#1da1f2] py-1">
        Select Vehicle Type
      </p> */}
      <div className="mb-2">
        <button
          id="multiLevelDropdownButton"
          data-dropdown-toggle="multi-dropdown"
          className="flex justify-between py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer"
          type="button"
          onClick={()=>setDropDown(!dropDown)}>
          {getDescription(props.vehicleType)}
          <svg
            className={`w-2.5 h-2.5 ms-3 ${dropDown ? "rotate-180" : ""}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        {dropDown && (
          <div className="w-full text-gray-700 bg-white focus:outline-none rounded-lg p-1 my-2 max-h-[15rem] overflow-y-scroll text-sm text-center shadow-md drop-shadow-[0_0px_10px_rgba(0,0,0,0.1)] hover:shadow-lg show-custom-scrollbar">
            { (
              <>
                {vehicles.map((vehicle, index) => (
                  <div
                    key={index}
                    id="multi-dropdown"
                    className="w-full text-gray-700 bg-white focus:outline-none  text-sm px-2 text-center inline-flex items-center  ">
                    <ul className="w-full py-1 text-sm text-gray-700">
                      <li>
                        <button
                          id="doubleDropdownButton"
                          data-dropdown-toggle="doubleDropdown"
                          data-dropdown-placement="right-start"
                          type="button"
                          className="w-full text-gray-700 bg-white hover:bg-gray-100 focus:outline-none  z-[1000] text-sm px-3 py-1 flex justify-between items-center"
                          onClick={() => toggleDoubleDropdown(index)}>
                          {vehicle.category}
                          <svg
                            className={`w-2.5 h-2.5 ms-3 ${vehicle.open ? "rotate-180" : ""
                              }`}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6">
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>

                        {vehicle.open && (
                          <div
                            id="doubleDropdown"
                            className="w-full text-gray-700 bg-white focus:outline-none  rounded-lg text-sm pl-5 pr-2 text-left inline-flex items-center">
                            <ul className="w-full py-2 text-sm text-gray-700 dark:text-gray-200">
                              {vehicle.vehicle.map((type, i) => (
                                <li
                                  onClick={() => {
                                    props.setVehicleType(type.type)
                                    setDropDown(false);
                                    props.setFormChanged(true)
                                  }}
                                  className="rounded-lg pl-3 pr-1 py-1 my-1 text-left flex gap-2 cursor-pointer w-full text-gray-700 bg-white hover:bg-gray-100 items-center justify-between	">
                                  <a className="  focus:outline-none   text-sm  text-left inline-flex">
                                    {type.description}
                                  </a>
                                  <img
                                    src={`https://cdn.tollguru.com/github/North_America/${type.type}.png`}
                                    className="h-6"
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VehicleInput;
