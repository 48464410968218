import React, { useEffect, useState } from "react";
import { createDataTable } from "../components/reusableComponents/DataTable";
// import { useNavigate } from "react-router-dom";
import { faker } from "@faker-js/faker";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { CachedTwoTone, SettingsSuggestTwoTone, SettingsVoiceOutlined } from "@mui/icons-material";
import { filterButtonStyles } from "../components/utils";
import { createDateFilter } from "../components/DateFilter";
import axios from "axios";
import SettingsIcon from "@mui/icons-material/Settings";
import ModalWrapper from "../components/ModalWrapper";
import VehicleSettingsForm from "../components/vehicleSettings/VehicleSettingsForm";
import { getDescription } from "../components/vehicleSettings/vehicleInput";
import VehicleDropDown from "../components/reusableComponents/VehicleDropDown";
import {  useInfiniteQuery, useQuery } from "@tanstack/react-query";
function VehicleSettingsModal({ open, onClose, vehicle, setVehicles, vehicles }) {
  useEffect(() => {
    const fetchData = async () => {
      if (!open || !vehicle) return;
    };

    fetchData();
  }, [open, vehicle]);

  return (
    <ModalWrapper open={open} onClose={onClose} width={"500px"}>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
          paddingTop: "0.75rem",
          borderRadius: "4px",
          outline: "none",
          height: "50vh",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <VehicleSettingsForm
          setVehicles={setVehicles}
          vehicleData={vehicle}
          closeModal={onClose}
          vehicles={vehicles}
        />
      </Box>
    </ModalWrapper>
  );
}
const fetchVehicles = async ({ pageParam = 1, selectedVehicleId }) => {
  let url = `${process.env.REACT_APP_BASE_URL}/vehicles?application_type=geotab&page=${pageParam}&limit=150`;
  
  if (selectedVehicleId && selectedVehicleId !== "all") {
    url += `&vehicle_id=${selectedVehicleId}`;
  }
  
  const response = await axios.get(url);
  // Assuming the response structure is as described
  return {
    vehicles: response.data.vehicles, // Data for the current page
    nextPage: response.data.hasNextPage ? response.data.nextPage : undefined,
  };
};

// Custom hook
const VehicleList = ({ navigate, allVehicleName }) => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
  // const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [syncingVehicles, setSyncingVehicles] = useState(false);
  // dropdown
  const [selected, setSelected] = useState({
    vehicle_name: "",
    vehicle_id: "all",
  });
  // pagination states
  const [backendPageNo, setBackendPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  // edit vehicle state
  const [showSettingsModal, setShowSettingsModal] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();

  const { data, isLoading, isError, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['vehicles', selected.vehicle_id],
    queryFn: ({ pageParam = 1 }) => fetchVehicles({ pageParam, selectedVehicleId: selected.vehicle_id }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    staleTime: Infinity, // Adjust based on your application's needs
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours, for example
  });
  const DataTable  = createDataTable({
    rows: data?.pages?.flatMap(i=>i.vehicles),
    columns: ({ column }) => [
      column(
        "edit_vehicle",
        {
          header: "Edit Vehicle",
          cell: (item) => (
            <Tooltip title="Edit Vehicle Detail" arrow>
              <IconButton
                sx={(theme) => ({
                  "&:hover": { background: theme.colors.primary.lighter },
                  color: theme.palette.primary.main,
                })}
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  console.log("item val", item.getValue());
                  setShowSettingsModal(true);
                  setSelectedVehicle(item.getValue());
                }}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          ),
          meta: { export: false, align: "center" },
          enableSorting: false,
        },
        (row) => row
      ),
      column("name", {
        header: "Vehicle Name",
        cell: (item) => (
          <Box
            sx={{
              fontSize: "14px !important",
              // paddingLeft: "5px !important",
            }}
          >
            {item.row.original.name}
          </Box>
        ),
        enableSorting: false,
      }),
      column("geotab_vehicle_id", {
        header: "Vehicle ID",
        cell: (item) => (
          <Box
            sx={{
              fontSize: "14px !important",
              // paddingLeft: "5px !important",
            }}
          >
            {item.row.original.geotab_vehicle_id}
          </Box>
        ),
        enableSorting: false,
        enableGlobalFilter: true,
      }),
      column("gpsToll", {
        header: "GPS Tolls",
        type: "currency",
        meta: { align: "right", export: true },
      }),
      // column("total_trips", { header: "Total Trips", meta: { align: "right" } }),
      column("vin", { header: "VIN", meta: { isVIN: "true", export: true } }),
      column(
        "license_plate_number",
        { header: "License Plate", meta: { export: true }, enableGlobalFilter: true }
        // (data) => data.entry_plaza_name || "-"
      ),
      column(
        "vehicle_type",
        {
          header: "GPS Vehicle Type",
          cell: (item) => (
            <Box
              sx={{
                fontSize: "14px !important",
                // paddingLeft: "5px !important",
              }}
            >
              {getDescription(item.row.original.vehicle_type)}
            </Box>
          ),
          meta: { export: true },
          enableGlobalFilter: true,
        }
        // (data) => data.entry_plaza_name || "-"
      ),
      column(
        "weight",
        { header: "Weight (lbs)", meta: { export: true }, enableGlobalFilter: true }
        // (data) => data.entry_plaza_name || "-"
      ),
      column("height", { header: "Height (ft)", meta: { export: true }, enableGlobalFilter: true }),
      column(
        "length",
        { header: "Length (ft)", meta: { export: true }, enableGlobalFilter: true }
        // (data) => data.entry_plaza_name || "-"
      ),
    ],
    id_column_name: "vehicle_id",
  });

  const syncVehicles = async () => {
    // Make the PUT request using axios
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/vehicles/syncVehicles`,
        {}
      );

      console.log("Sync Vehicles Response:", response.data);
      // window.location.reload();
    } catch (error) {
      console.log("vehicle sync error", error);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl pl-4 pt-6 pb-2 font-medium">My Vehicles</h1>
      </div>
      <DataTable
        infinitePagination
        initialState={{
          pagination: {
            pageIndex: currentPage - 1,
            pageSize: rowsPerPage, // Set the initial page number to 2
          },
        }}
        loading={isLoading || isFetching}
        // onRowClick={(id) => {
        //   navigate("trip-list", id);
        // }}
        onRowClick={() => {}}
        setRowsPerPage={setRowsPerPage}
        onPageChange={async (isLastPage, requestingNextPage, setPageIndex, page) => {
          if (requestingNextPage && isLastPage) {
            await fetchNextPage() // Increment page number
            setPageIndex(page);
            // setCurrentPage(currentPage+1)
          } else {
            setPageIndex(page);
          }
          setCurrentPage(page + 1);
        }
          }
        cursor_pointer={true}
      >
        {/* <DateFilter /> */}

        {/* <DataTable.Search /> */}
        <div className="flex flex-wrap gap-2 justify-between w-full items-center">
          <div className="w-1/12"></div>
          {/* <Button
            startIcon={<CachedTwoTone />}
            sx={filterButtonStyles}
            className="items-center px-3 hidden"
            onClick={() => {
              setSyncingVehicles(true);
              syncVehicles().finally(() => {
                setSyncingVehicles(false);
              });
            }}
            disabled={loading}
          >
            {syncingVehicles ? "Syncing..." : "Sync Vehicles"}
          </Button> */}
          <VehicleDropDown
            allVehicleName={allVehicleName}
            selected={selected}
            setSelected={setSelected}
            setCurrentPage={setCurrentPage}
          />
          <DataTable.Export filename={"my-vehicles.csv"} />
        </div>
      </DataTable>
      <VehicleSettingsModal
        setVehicles={setVehicles}
        vehicles={vehicles}
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        // date={{ start: "2023-06-01", end: "2023-08-31" }}
        vehicle={selectedVehicle}
      />
    </>
  );
};

export default VehicleList;
