import React, { useEffect, useState } from "react";
import { createDataTable } from "../components/reusableComponents/DataTable";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { LibraryAddTwoTone, SettingsSuggestTwoTone } from "@mui/icons-material";
import ModalWrapper from "../components/ModalWrapper";
import VehicleSettingsForm from "../components/vehicleSettings/VehicleSettingsForm";
import axios from "axios";
import NewVehicleForm from "../components/vehicleSettings/NewVehicleForm";

function VehicleSettingsModal({ open, onClose, vehicle, date, setVehicles }) {
  useEffect(() => {
    const fetchData = async () => {
      if (!open || !vehicle) return;
    };

    fetchData();
  }, [open, vehicle, date]);

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
          paddingTop: "0.75rem",
          borderRadius: "4px",
          outline: "none",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <VehicleSettingsForm setVehicles={setVehicles} vehicleData={vehicle} closeModal={onClose} />
      </Box>
    </ModalWrapper>
  );
}

function NewVehicleModal({ open, onClose }) {
  useEffect(() => {
    const fetchData = async () => {
      if (!open) return;
    };

    fetchData();
  }, [open]);

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
          paddingTop: "0.75rem",
          borderRadius: "4px",
          outline: "none",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {/* <VehicleSettingsForm setVehicles={setVehicles} vehicleData={vehicle} closeModal={onClose} /> */}
        <NewVehicleForm closeModal={onClose} />
      </Box>
    </ModalWrapper>
  );
}

const Settings = ({ navigate }) => {
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  const [showNewVehicleModal, setShowNewVehicleModal] = useState();
  const [showSettingsModal, setShowSettingsModal] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();

  const DataTable = createDataTable({
    rows: vehicles,
    columns: ({ column }) => [
      column(
        "edit_vehicle",
        {
          header: "Edit Vehicle",
          cell: (item) => (
            <Tooltip title="Edit Vehicle Detail" arrow>
              <IconButton
                sx={(theme) => ({
                  "&:hover": { background: theme.colors.primary.lighter },
                  color: theme.palette.primary.main,
                })}
                color="inherit"
                size="small"
                onClick={() => {
                  console.log("item val", item.getValue());
                  setShowSettingsModal(true);
                  setSelectedVehicle(item.getValue());
                }}
              >
                <SettingsSuggestTwoTone />
              </IconButton>
            </Tooltip>
          ),
          meta: { export: false, align: "center" },
          enableSorting: false,
        },
        (row) => row
      ),
      column("vehicle_name", {
        header: "Name",
        meta: { export: true },
        enableGlobalFilter: true,
      }),
      column("vehicle_make", {
        header: "Make",
        meta: { export: true },
        enableGlobalFilter: true,
      }),
      column("vehicle_model", {
        header: "Model",
        meta: { export: true },
        enableGlobalFilter: true,
      }),
      // column('driver_id', { header: 'Driver ID', meta: { export: true }, enableGlobalFilter: true }),
      column(
        "license_plate",
        { header: "License Plate", meta: { export: true }, enableGlobalFilter: true }
        // (data) => data.entry_plaza_name || "-"
      ),
      column(
        "license_plate_state",
        { header: "License Plate State", meta: { export: true }, enableGlobalFilter: true }
        // (data) => data.exit_plaza_name || "-"
      ),

      column("vehicle_type", { header: "Type", meta: { export: true }, enableGlobalFilter: true }),
      column("vehicle_class", {
        header: "Class",
        meta: { export: true },
        enableGlobalFilter: true,
      }),
      column("axle_count", {
        header: "Axle Count",
        meta: { export: true },
        enableGlobalFilter: true,
      }),

      column("tire_count", {
        header: "Tire Count",
        meta: { export: true },
        enableGlobalFilter: true,
      }),

      column("vehicle_weight", {
        header: "Weight (lbs)",
        meta: { export: true },
        enableGlobalFilter: true,
      }),
      column("vehicle_height", {
        header: "height (ft)",
        meta: { export: true },
        enableGlobalFilter: true,
      }),
      //   column("tag_number", {
      //     header: "Tag Number",
      //     meta: { export: true },
      //     enableGlobalFilter: true,
      //   }),
      //   column("tag_issuing_agency", {
      //     header: "Tag Issuing Agency",
      //     meta: { export: true },
      //     enableGlobalFilter: true,
      //   }),

      //   column("tag_type", { header: "Tag Type", meta: { export: true }, enableGlobalFilter: true }),
    ],
    id_column_name: "transaction_id",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://geotab-backend.onrender.com/api/vehicles/sorted-vehicle"
        );
        setVehicles(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="flex justify-between mt-10">
        <h1 className="text-2xl px-4 my-4 font-medium">My Vehicles</h1>
        <div className="flex flex-col justify-center ">
          <button
            onClick={() => setShowNewVehicleModal(true)}
            className="flex border p-1  rounded-md text-sm font-bold text-gray-500 bg-gray-100"
          >
            <IconButton
              sx={(theme) => ({
                "&:hover": { background: theme.colors.primary.lighter },
                color: theme.palette.primary.main,
              })}
              color="inherit"
              size="small"
            >
              <LibraryAddTwoTone />
            </IconButton>{" "}
            <div className="flex flex-col justify-center  h-full ml-1 mr-2">Add Vehicle</div>
          </button>
        </div>
      </div>
      <DataTable
        infinitePagination
        loading={loading}
        onRowClick={() => {}}
        cursor_pointer={true}
        initialState={{
          sorting: [{ id: "transaction_id", desc: true }],
        }}
      >
        <DataTable.Search />
        <DataTable.Export />
      </DataTable>

      <VehicleSettingsModal
        setVehicles={setVehicles}
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        date={{ start: "2023-06-01", end: "2023-08-31" }}
        vehicle={selectedVehicle}
      />
      <NewVehicleModal open={showNewVehicleModal} onClose={() => setShowNewVehicleModal(false)} />
    </>
  );
};

export default Settings;
