const globalNumberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const denominations = {
  K: 1e3,
  M: 1e6,
  B: 1e9,
  T: 1e12,
};

export function formatCurrency(value, short) {
  if (!short) return globalNumberFormatter.format(value);

  const absValue = Math.abs(value);
  let denomination = "default";

  if (short) {
    if (absValue >= denominations.T) {
      denomination = "T";
    } else if (absValue >= denominations.B) {
      denomination = "B";
    } else if (absValue >= denominations.M) {
      denomination = "M";
    } else if (absValue >= denominations.K) {
      denomination = "K";
    }
  }

  return `${(value / denominations[denomination] || 0).toLocaleString("en-US", {
    maximumFractionDigits: denomination === "K" || denomination === "default" ? 0 : 1,
    style: "currency",
    currency: "USD",
  })}${denomination !== "default" ? denomination : ""}`;
}

export function formatFigure(value) {
  const hasDecimal = value % 1 !== 0;
  const options = {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: hasDecimal ? 20 : 0,
  };
  const formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(value);
}

export const formatNumber = (num) => {
  // Check if the number is in millions
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  }
  // Check if the number is in thousands
  else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  // If the number is less than 1000, just round it to nearest whole number
  else {
    return Math.round(num).toString();
  }
};

export function formatDateTime(timestamp, time = true) {
  if (!timestamp) return "";
  const date = typeof timestamp === "string" ? new Date(timestamp) : timestamp;
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(time && { hour: "2-digit", minute: "2-digit", second: "2-digit" }),
  };
  return new Intl.DateTimeFormat("en-US", options).format(date).replace(",", " - ");
}
export function getFormatedDateTime(utcTimestamp, time = true) {
  if (!utcTimestamp) return "";
  return time
    ? utcTimestamp
        .replace("T", " - ")
        .replace(/\.000Z/, "")
        .replace(/Z$/, "")
    : utcTimestamp.split("T").at(0);
}

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));
  return formattedSize + " " + sizes[i];
}



